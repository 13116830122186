import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'app/store/userSlice';

const ErrorDialog = (openErrorDlg) => {
  const [open, setOpen] = useState(openErrorDlg);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
    setOpen(false);
    sessionStorage.removeItem('showErrorPopup');
    window.location.reload();
  };

  return (
    <Dialog
      open={open}
      onClose={handleLogout}
      sx={{
        '& .MuiDialog-container': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '400px',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '16px 7px',
        }}
      >
        <Alert
          severity="error"
          sx={{
            borderRadius: '8px',
            fontSize: '1.4rem',
            padding: '2px 16px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          Error
        </Alert>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: '1.4rem' }}>An unexpected error occurred. Please log in again.</Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          onClick={handleLogout}
          sx={{
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '50px',
            fontSize: '1.2rem',
            padding: '6px 16px',
            '&:hover': { backgroundColor: 'black', color: 'white' },
          }}
          variant="contained"
          size="small"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
