import { Box, FormLabel, Card, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import ActionRendererComponent from 'app/shared-components/action-render/ActionRendererComponent';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { handleResponse } from 'app/shared-components/index';
import { useState } from 'react';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import MuiButton from 'app/shared-components/mui/button/MuiButton';

const ATMEJournalExtractFile = ({ getAllUploadedFileDetails, rowData }) => {
  const [file1, setFile1] = useState(undefined);
  const [file2, setFile2] = useState(undefined);

  function downloadCSVFile(img, reqId) {
    console.log(img);
    console.log(reqId);
    const linkSource = `data:application/csv;base64,${img}`;
    const downloadLink = document.createElement('a');
    const fileName = `${reqId}`;
    downloadLink.href = linkSource;
    downloadLink.target = '_blank';
    downloadLink.download = fileName;
    downloadLink.click();
  }
  const viewReceipt = async (value) => {
    const data = {
      fileNameWithPath: `${value.outputFilePath}${value.outputFileName}`,
    };
    console.log(data);
    const res = await axios.post(jwtServiceConfig.fpViewFileBase64, data);
    console.log(res);
    if (res.data.responseCode === 0) {
      const img = res?.data?.data?.base64String;
      const reqId = value?.outputFileName;
      downloadCSVFile(img, reqId);
    } else {
      handleResponse(res);
    }
  };
  const handleView = (data) => {
    viewReceipt(data);
  };

  const columnDefsATMFile = [
    { field: 'id', headerName: 'Job ID' },
    { field: 'ejFileName', headerName: 'ATM E-Journal File' },
    { field: 'switchExtractFileName', headerName: 'Switch Extract File' },
    { field: 'lastUpdatedBy', headerName: 'Uploaded By' },
    {
      field: 'createdDateTime',
      headerName: 'DateTime',
      type: 'dataTimeFormat',
    },
    { field: 'jobStatus', headerName: 'Status' },
    { field: 'outputFileName', headerName: 'Output File Name' },
    {
      field: 'report',
      headerName: 'Download',
      cellRenderer: ({ data }) => ActionRendererComponent('isViewFile', data, handleView),
    },
  ];

  const handleSubmitATMFile = async (values, resetForm) => {
    console.log(file1);
    console.log(file2);
    const formData = new FormData();
    formData.append(
      'jsonData',
      JSON.stringify({
        jobType: 'EJ_SW',
        fileDefinitions: [
          { fileName: values?.atmEJournalFile.name, definitionName: 'EJF' },
          { fileName: values?.switchExtractFile.name, definitionName: 'EXT' },
        ],
      })
    );
    formData.append('fileList', values?.atmEJournalFile);
    formData.append('fileList', values?.switchExtractFile);
    const res = await axios.post(jwtServiceConfig.fpUploadMultipleFile, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(res?.data);
    if (res.data.responseCode === 0) {
      handleResponse(res);
      getAllUploadedFileDetails();
      resetForm();
    } else {
      handleResponse(res);
    }
  };
  return (
    <>
      <Box className=" text-sm">
        This section pertains to a process called 3-way reconcilation. Please submit the switch Extract File, e-Journal File (optional), and CBS File in order to compile the R3 Report. The files will
        be uploaded to the R3 Server and used to reconcile the data with R3 Transactions in order to create a report.
      </Box>
      <Box>
        <Formik initialValues={{ atmEJournalFile: undefined, switchExtractFile: undefined }} onSubmit={handleSubmitATMFile} validateOnBlur={false}>
          {({ values, setFieldValue, resetForm }) => (
            <Box className="my-4 mr-8">
              <Box className="mt-8 ">
                <Form noValidate>
                <Box className="flex flex-col sm:flex-row md:flex-row gap-6 my-8 px-4 sm:px-8 md:px-8">
                    <Card className="flex flex-col items-start w-full h-[120px] p-24 rounded-none overflow-auto ">
                      <Box className="flex-column my-8">
                        <Box className="my-8">
                          <FormLabel>ATM E-Journal File : (File 1)</FormLabel>
                        </Box>
                        <div className="flex items-center">
                          <div>
                            <span className=" text-sm">
                              {values?.atmEJournalFile ? values?.atmEJournalFile?.name : 'Select File'}
                              {values?.atmEJournalFile && (
                                <CloseIcon
                                  onClick={() => {
                                    setFieldValue('atmEJournalFile', undefined);
                                    setFile1(undefined);
                                  }}
                                  style={{ marginLeft: '8px', fontSize: '16px' }}
                                />
                              )}
                            </span>
                          </div>
                          <div className="mx-12">
                            <MuiButton type="basic" component="label">
                              <Typography className="text-13 font-medium tracking-tight">Browse</Typography>
                              <input
                                type="file"
                                name="atmEJournalFile"
                                required
                                multiple
                                hidden
                                accept=".txt,.jrn"
                                onChange={(event) => {
                                  console.log(event);
                                  setFile1(event.currentTarget.files[0]);
                                  setFieldValue('atmEJournalFile', event.currentTarget.files[0]);
                                }}
                              />
                            </MuiButton>
                          </div>
                        </div>
                      </Box>
                    </Card>
                    <Card className="flex flex-col items-start w-full h-[120px] p-24 rounded-none overflow-auto ">
                      <Box className="flex-column my-8">
                        <Box className="my-8">
                          <FormLabel>Switch Extract File : (File 2)</FormLabel>
                        </Box>
                        <div className="flex items-center">
                          <div>
                            <span className=" text-sm">
                   
                              {values?.switchExtractFile ? values?.switchExtractFile?.name : 'Select File'}
                              {values?.switchExtractFile && (
                                <CloseIcon
                                  onClick={() => {
                                    setFieldValue('switchExtractFile', undefined);
                                    setFile2(undefined);
                                  }}
                                  style={{ marginLeft: '8px', fontSize: '16px' }}
                                />
                              )}
                            </span>
                          </div>
                          <div className="mx-12">
                            <MuiButton type="basic" component="label">
                              <Typography className="text-13 font-medium tracking-tight">Browse</Typography>

                              <input
                                type="file"
                                name="switchExtractFile"
                                required
                                multiple
                                hidden
                                accept=".txt"
                                onChange={(event) => {
                                  console.log(event);
                                  setFile2(event.currentTarget.files[0]);
                                  setFieldValue('switchExtractFile', event.currentTarget.files[0]);
                                }}
                              />
                            </MuiButton>
                          </div>
                        </div>
                      </Box>
                    </Card>

                    <Box>
                      <Box className="flex justify-end my-20 px-8">
                        <Box>
                          <MuiButton
                            type="basic"
                            sx={{ marginLeft: '10px' }}
                            disabled={values?.atmEJournalFile === undefined || values?.switchExtractFile === undefined}
                            onClick={() => handleSubmitATMFile(values, resetForm)}
                          >
                            Reconcile
                          </MuiButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
      <Box className="mt-32">
        <AgGrid rowData={rowData} columnDefs={columnDefsATMFile} overlayNoRowsTemplate="No records found" />
      </Box>
    </>
  );
};
export default ATMEJournalExtractFile;
