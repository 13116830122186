import { Card, CardContent, Chip, DialogActions, DialogContent, DialogTitle, Checkbox, FormGroup, FormControlLabel, Switch, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import axios from 'axios';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { useState } from 'react';
import CustomDialogBox from 'app/shared-components/custom-dialog-box/CustomDialog';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { handleResponse } from 'app/shared-components/index';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import FormikController from 'app/shared-components/formik-controller/FormikController';

const TerminalCard = ({ terminal, getTerminalDetails, locationCode }) => {
  const [pageControl, setPageControl] = useState({
    isOpen: false,
    isTerminalStatus: '',
    isTerminalOperatingStatus: '',
    isRemarksModal: false,
  });
  const [servicesValues, setServicesValues] = useState([]);

  const getServicesList = async (services) => {
    console.log(services);
    const res = await axios.post(jwtServiceConfig.serviceView, {});
    if (res.data.responseCode === 0) {
      console.log(res);
      setServicesValues(() => {
        if (services) {
          return res?.data?.data?.map((item) => (services.includes(item.serviceName) ? { ...item, isChecked: true } : { ...item, isChecked: false }));
        }
        return res?.data?.data?.map((item) => ({ ...item, isChecked: false }));
      });
      setPageControl((prev) => ({
        ...prev,
        isOpen: true,
        isTerminalStatus: terminal?.terminalStatus,
        isTerminalOperatingStatus: terminal?.operatingStatus,
      }));
    } else {
      handleResponse(res);
    }
  };

  const handleSaveTerminalServices = () => {
    const serviceCodeList = servicesValues.reduce((acc, cur) => {
      if (cur.isChecked === true) {
        acc.push(cur.serviceCode);
      }
      return acc;
    }, []);
    const data = {
      id: terminal?.id,
      agencyCode: terminal?.agencyCode,
      terminalId: terminal?.terminalId,
      operatingStatus: pageControl?.isTerminalOperatingStatus,
      locationCode,
      serviceCodeList,
      terminalType: terminal?.terminalType,
    };

    console.log(data);

    axios.post(jwtServiceConfig.terminalUpdateTerminalOperatingStatus, data).then(async (res) => {
      console.log(res);
      if (res.data.responseCode === 0) {
        handleResponse(res);

        setPageControl((prev) => ({
          ...prev,
          isOpen: false,
        }));
        await getTerminalDetails();
      } else {
        handleResponse(res);
        setPageControl((prev) => ({
          ...prev,
          isTerminalStatus: prev.isTerminalStatus,
          isTerminalOperatingStatus: prev.isTerminalOperatingStatus,
        }));
        getTerminalDetails();
      }
    });
  };

  const handleChangeTerminalStatus = () => {
    const data = {
      terminalStatus: pageControl.isTerminalStatus,
      terminalId: terminal?.terminalId,
    };
    console.log(data);
    axios.post(jwtServiceConfig.terminalStatusChange, data).then(async (res) => {
      console.log(res);
      if (res.data.responseCode === 0) {
        handleResponse(res);

        setPageControl((prev) => ({
          ...prev,
          isRemarksModal: false,
          isTerminalStatus: !prev.isTerminalStatus === 'A' ? 'I' : 'A',
        }));
        await getTerminalDetails();
      } else {
        handleResponse(res);
      }
    });
  };

  return (
    <>
      <Card className="flex flex-col h-320 shadow">
        <CardContent className="flex flex-col flex-auto p-24 overflow-auto">
          <div className="w-full h-full relative">
            <div className="flex flex-row items-center justify-between mb-16">
              <div className="w-200">
                <Typography color="inherit" className="text-left text-28 sm:text-20 font-bold tracking-tight mt-4">
                  {terminal?.terminalName}
                </Typography>
                <Chip className="font-sm mt-4" label={terminal?.terminalId} size="small" />
              </div>
              <div className="flex flex-col justify-end w-140">
                {terminal?.operatingStatus === 'Y' ? (
                  <div className="">
                    <div className="flex">
                      <FuseSvgIcon className="text-green-600" size={20}>
                        heroicons-solid:badge-check
                      </FuseSvgIcon>
                      <Typography className="text-13 mt-2 line-clamp-2 ml-4" color="text.secondary">
                        Active
                      </Typography>
                    </div>
                    <div>
                      {terminal?.isBusy === 'N' ? (
                        <div className="flex">
                          <FuseSvgIcon className="text-blue-600" size={20}>
                            heroicons-solid:clock
                          </FuseSvgIcon>
                          <Typography className="text-13 mt-2 line-clamp-2 ml-4" color="text.secondary">
                            Idle
                          </Typography>
                        </div>
                      ) : (
                        <div className="flex">
                          <FuseSvgIcon className="text-yellow-600" size={20}>
                            material-solid:autorenew
                          </FuseSvgIcon>
                          <Typography className="text-13 mt-2 line-clamp-2 ml-4" color="text.secondary">
                            In Operation
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <FuseSvgIcon className="text-red-600" size={20}>
                      heroicons-solid:x-circle
                    </FuseSvgIcon>
                    <Typography className="text-13 mt-2 line-clamp-2 ml-4" color="text.secondary">
                      Inactive
                    </Typography>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-20 overflow-auto">
              {terminal?.serviceList?.length === 0 && (
                <div className="flex flex-1 items-center justify-center">
                  <Typography color="text.secondary" className="text-12">
                    No Services found!
                  </Typography>
                </div>
              )}
              {terminal?.serviceList?.map((item) => (
                <div className="flex items-center my-12" key={item}>
                  <FuseSvgIcon className="text-green-600" size={20}>
                    material-solid:chevron_right
                  </FuseSvgIcon>
                  <Typography color="text.secondary" className="text-12">
                    {item}
                  </Typography>
                </div>
              ))}
            </div>
            <div className="absolute bottom-0 w-full">
              <div className="flex justify-end">
                <MuiButton type="basic" onClick={() => getServicesList(terminal?.serviceList)}>
                  Change
                </MuiButton>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <CustomDialogBox
        open={pageControl.isOpen}
        children={
          <>
            <DialogTitle>
              <Typography className="text-2xl font-semibold tracking-tight leading-8">
                {terminal?.terminalName} - {terminal?.terminalId}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <div className="my-12">
                <FormGroup>
                  {servicesValues?.map((srv) => (
                    <FormControlLabel
                      key={srv.id}
                      control={
                        <Checkbox
                          checked={srv.isChecked === true}
                          onChange={() => setServicesValues((prev) => prev.map((item) => (item.id === srv.id ? { ...item, isChecked: !item.isChecked } : item)))}
                        />
                      }
                      label={srv.serviceName}
                    />
                  ))}
                </FormGroup>
              </div>
            </DialogContent>
            <DialogActions className="justify-between">
              <div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={pageControl.isTerminalOperatingStatus === 'Y'}
                          onChange={() =>
                            setPageControl((prev) => ({
                              ...prev,
                              // isRemarksModal: true,
                              isTerminalOperatingStatus: prev.isTerminalOperatingStatus === 'Y' ? 'N' : 'Y',
                            }))
                          }
                        />
                      }
                      label="Operating Status"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="flex flex-row">
                <div>
                  <MuiButton
                    type="cancel-btn"
                    onClick={() => {
                      setPageControl((prev) => ({
                        ...prev,
                        isOpen: false,
                        isTerminalStatus: '',
                        isTerminalOperatingStatus: '',
                      }));
                      setServicesValues([]);
                    }}
                  >
                    Cancel
                  </MuiButton>
                </div>
                <div className="px-8">
                  <MuiButton type="basic" onClick={() => handleSaveTerminalServices()}>
                    Save
                  </MuiButton>
                </div>
              </div>
              {/* <div className="my-16">
                <div className="flex justify-end">
                  
                </div>
              </div> */}
            </DialogActions>
          </>
        }
      />
      <Formik initialValues={{ remarks: '' }} onSubmit={handleChangeTerminalStatus} validateOnBlur={false}>
        {() => (
          <CustomDialogBox
            open={pageControl.isRemarksModal}
            children={
              <Form noValidate>
                <DialogTitle>{pageControl?.isTerminalStatus === 'A' ? 'Activate Terminal' : 'Inactive Terminal'}</DialogTitle>
                <DialogContent className="mt-20">
                  <FormikController control="textField" name="remarks" label="Remarks" required />
                </DialogContent>
                <DialogActions>
                  <div className="flex flex-row">
                    <div className="px-4">
                      <MuiButton
                        type="cancel-btn"
                        onClick={() => {
                          setPageControl((prev) => ({
                            ...prev,
                            isRemarksModal: false,
                            isTerminalStatus: prev.isTerminalStatus === 'A' ? 'I' : 'A',
                          }));
                        }}
                      >
                        Cancel
                      </MuiButton>
                    </div>
                    <div className="pl-8 ">
                      <MuiButton type="submit">Save</MuiButton>
                    </div>
                  </div>
                </DialogActions>
              </Form>
            }
          />
        )}
      </Formik>
    </>
  );
};

export default TerminalCard;
