import { AgGridReact } from 'ag-grid-react';
import { InputLabel } from '@mui/material';
import { useCallback, useRef, useMemo, forwardRef, useEffect, useState } from 'react';
import AmountCellRenderer from './components/AmountCellRenderer';
import { handleDateTime } from '../page-utils/pageUtils';
/* Reusable Ag-grid component */

const AgGrid = forwardRef((props, ref) => {
  const { columnDefs, rowData, gridOptions, overlayNoRowsTemplate, onGridReady, treeData, getDataPath, defaultExcelExportParams, excelStyles, autoGroupColumnDef } = props;
  const gridRef = useRef();
  const onPageSizeChanged = useCallback(() => {
    const { value } = document.getElementById('page-size');
    const refs = ref || gridRef;
    refs.current.api.paginationSetPageSize(Number(value));
  }, []);

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const autoSizeStrategy = {
    type: 'fitCellContents',
    columnLimits: [
      {
        colId: 'actions',
        minWidth: 500,
      },
    ],
  };

  const defaultColDef = {
    resizable: true,
    autoHeight: true,
    filter: true,
    sortable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    minWidth: 120,
    maxWidth: 400,
    cellClassRules: {
      'cell-wrap-text': (params) => params?.value?.length > 20,
    },
    // menuTabs: ['filterMenuTab'],
    cellDataType: false,
    cellStyle: {
      wordBreak: 'break-word',
    },
  };

  const autoSizeColumnsAfterDataLoad = useCallback((params) => {
    const allColumnIds = [];

    params.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    const columns = params.columnApi.getColumns();
    if (!columns) return;
    setTimeout(() => {
      params.columnApi.autoSizeColumns(columns.map((column) => column.getId()));

      const totalColumnWidth = columns.reduce((acc, column) => {
        const columnState = params.columnApi.getColumnState().find((c) => c.colId === column.getColId());
        return acc + (columnState?.width || 0);
      }, 0);

      const gridWidth = document.querySelector('.ag-body-viewport')?.clientWidth || 0;
      const isFullWidth = totalColumnWidth >= gridWidth;

      if (isFullWidth && allColumnIds?.length > 5) {
        params.columnApi.autoSizeColumns(columns.map((column) => column.getId()));
      } else {
        params.api.sizeColumnsToFit();
      }
    }, 10);
  }, []);

  const columnTypes = useMemo(() => {
    return {
      amountCellRenderer: {
        minWidth: 140,
        cellRenderer: AmountCellRenderer,
      },
      dataTimeFormat: {
        valueFormatter: ({ value }) => handleDateTime(value),
      },
      dataTimeDayFormat: {
        valueFormatter: ({ value }) => handleDateTime(value, 'D'),
      },
    };
  }, []);

  const onRowClicked = (params) => {
    document.querySelectorAll('.selected-row').forEach((row) => {
      row.classList.remove('selected-row');
    });

    const rowElement = params.event.target.closest('.ag-row');
    if (rowElement) {
      rowElement.classList.add('selected-row');
    }
  };

  return (
    <div style={containerStyle} className="ag-theme-balham custom-aggrid">
      <div className="mt-10 mb-20 flex">
        <InputLabel className="text-sm"> Page Size &nbsp;:&nbsp;</InputLabel>
        <select onChange={onPageSizeChanged} id="page-size">
          <option value="10">10</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
        </select>
      </div>
      <div style={gridStyle}>
        <AgGridReact
          ref={ref || gridRef}
          columnDefs={columnDefs}
          rowData={rowData}
          rowSelection="single"
          onRowClicked={onRowClicked}
          suppressRowClickSelection
          gridOptions={gridOptions}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
          treeData={treeData}
          autoSizeStrategy={autoSizeStrategy}
          getDataPath={getDataPath}
          defaultExcelExportParams={defaultExcelExportParams}
          excelStyles={excelStyles}
          suppressDragLeaveHidesColumns
          enableCellTextSelection
          autoGroupColumnDef={autoGroupColumnDef}
          defaultColDef={defaultColDef}
          onFirstDataRendered={autoSizeColumnsAfterDataLoad}
          onPaginationChanged={autoSizeColumnsAfterDataLoad}
          domLayout="autoHeight"
          pagination
          paginationPageSize={10}
          headerHeight={50}
          rowHeight={35}
          onGridSizeChanged={(params) => autoSizeColumnsAfterDataLoad(params)}
          // onGridSizeChanged={onGridSizeChanged}
          // onGridReady={handleGridReady}
          columnTypes={columnTypes}
        />
      </div>
    </div>
  );
});

export default AgGrid;
