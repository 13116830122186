import React, { useState } from 'react';
import { Paper, Box, Typography, MenuItem, Chip, Stack, InputAdornment, IconButton, Autocomplete, TextField, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { ErrorMessage, Field, Formik } from 'formik';
import * as Yup from 'yup';
import { handleResponse } from 'app/shared-components/index';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import axios from 'axios';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import SearchIcon from '@mui/icons-material/Search';
import MuiDialog from 'app/shared-components/mui/dialog/MuiDialog';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { usePermHook } from 'src/app/custom-hooks/user-permission-hook/userPermHook';
import { permissions } from 'src/app/custom-hooks/user-permission-hook/permissions';
import MuiFormDialog from 'app/shared-components/mui/dialog/MuiFormDialog';
import GridContainer from 'app/shared-components/mui/grid/GridContainer';
import GridItem from 'app/shared-components/mui/grid/GridItem';
import FormikController from 'app/shared-components/formik-controller/FormikController';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import ConfirmationDialog from '../confirmation-dialog-queue-management/ConfirmationDialog';
import { statusChangeMonitor } from '../helperFuntionQueueManagement';

/* Teller / Terminal Configuration
 * Teller / Terminal Configuration allows us to create the terminals, view terminals, and have the access to edit them.
 * There is a option to create a terminal or teller for each agency with different location.
 * There is a option to add multiple services for terminal or teller.
 */

const ActionRenderer = (data, handleView) => {
  return (
    <Box className="flex flex-row justify-start">
      <Box>
        <Box className=" text-black hover:cursor-pointer">
          <EditIcon onClick={() => handleView(data)} style={{ fontSize: '18px' }} />
        </Box>
      </Box>
    </Box>
  );
};

const getServiceValue = (service) => {
  if (service.length !== 0) {
    return (
      <Stack gap={1} direction="row" flexWrap="nowrap">
        {service.map((item) => (
          <Chip label={item} key={item} />
        ))}
      </Stack>
    );
  }
  return null;
};

const TerminalTable = () => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const [connectivityDropdown, setConnectivityDropdown] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [locationValues, setLocationValues] = useState([]);
  const [servicesValues, setServicesValues] = useState([]);
  /*   State for custom validation */
  const [userValidation, setUserValidation] = useState({
    validate: true,
    msg: 'Please enter valid User ID',
    isUserLocation: '',
  });
  const [agency, setAgency] = useState([]);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [cnfrmDlgOpen, setCnfrmDlgOpen] = useState(false);
  const [statusControl, setStatusControl] = useState({
    isOpenStsDlg: false,
    val: undefined,
  });
  const user = useSelector(selectUser);
  const dialogTitle = edit ? 'Edit Teller / Terminal' : 'Add Teller / Terminal';

  const { permission } = usePermHook(permissions.queueManagement.terminalPage);

  /* Cell renderer for services */
  const serviceValueGetter = (params) => {
    return params.data.serviceNameList !== null ? getServiceValue(params.data.serviceNameList) : null;
  };

  const initialValues = {
    agencyCode: '',
    locationName: '',
    locationCode: '',
    serviceNameList: '',
    serviceCodeList: [],
    terminalType: '',
    terminalId: '',
    terminalStatus: 'I',
    terminalName: '',
  };

  const validationSchema = Yup.object().shape({
    agencyCode: Yup.string().required('Required'),
    locationCode: Yup.string().required('Required'),
    serviceCodeList: Yup.array().min(1, 'Required'),
    terminalType: Yup.string().required('Required'),
    terminalName: Yup.string().required('Required').trim(),
    terminalId: Yup.string().max(16, 'Max 16 Characters').required('Required').matches(/^\S*$/, 'White space not allowed').trim(),
  });

  const getTerminalsList = async () => {
    const res = await axios.post(jwtServiceConfig.terminalView, {
      agencyCode: user.agencyCode,
    });
    console.log(res.data);
    if (res.data.responseCode === 0) {
      setRowData(res.data.data);
    } else {
      handleResponse(res);
    }
  };

  const getAllConnectivityDropdown = async () => {
    const res = await axios.post(jwtServiceConfig.masterGetAllConnectivityDropdown, {
      channelId: '07',
    });
    if (res.data.responseCode === 0) {
      setConnectivityDropdown(res.data.data);
      console.log(res.data.data);
    } else {
      handleResponse(res);
    }
  };

  const getServicesList = async () => {
    const res = await axios.post(jwtServiceConfig.serviceView, {});
    if (res.data.responseCode === 0) {
      setServicesValues(res.data.data);
      console.log(res.data.data);
    } else {
      handleResponse(res);
    }
  };

  const getAllAgencies = async () => {
    const res = await axios.post(jwtServiceConfig.bobGetAllAgencyCode, {
      agencyCode: user.agencyCode,
    });
    if (res.data.responseCode === 0) {
      setAgency(res.data.data);
    } else {
      handleResponse(res);
    }
  };

  const getAllLocationsList = async (agencyCode) => {
    setLocationValues([]);
    const res = await axios.post(jwtServiceConfig.locationGetLocationsAgencyWise, {
      agencyCode
    });
    if (res.data.responseCode === 0) {
      setLocationValues(res.data.data);
    } else {
      handleResponse(res);
    }
  };

  const getUserLocationOnEdit = async (userCode, locationCode, agencyCode) => {
    await axios
      .post(jwtServiceConfig.userGetUserCodesOrId, {
        userCode,
        agencyCode,
      })
      .then((response) => {
        console.log(response?.data);
        if (response?.data?.responseCode === 0) {
          setUserValidation((prev) => ({
            ...prev,
            isUserLocation: response?.data?.data?.locationCode,
          }));
        }
      });
  };

  /* Get all data for edit */
  const getEditApis = async (agencyCode, terminalId, locationCode) => {
    await Promise.all([
      getAllAgencies(),
      getTerminalsList(),
      getServicesList(),
      getAllConnectivityDropdown(),
      getAllLocationsList(agencyCode),
      getUserLocationOnEdit(terminalId, locationCode, agencyCode),
    ]);
  };

  const handleView = async (data) => {
    await getEditApis(data?.agencyCode, data?.terminalId, data?.locationCode);
    setSelectedValues(!Object.hasOwn(data, 'terminalName') ? { ...data, terminalName: '' } : data);
    setUserValidation((prev) => ({
      ...prev,
      validate: false,
      msg: '',
    }));
    setStatusControl((prev) => ({ ...prev, isOpenStsDlg: false }))
    setEdit(true);
    setOpen(true);
    console.log('terminal', data);
  };

  const handleSetStatus = (evnt, setFieldValue) => {
    const val = evnt.target.checked ? 'A' : 'I';
    setFieldValue('terminalStatus', val);
    setStatusControl((prev) => ({ ...prev, isOpenStsDlg: true, val }));
  };
  const handleChangeStatus = async () => {
    setStatusControl((prev) => ({ ...prev, isOpenStsDlg: false }));
  };
  const handleCloseStsDlg = (value, setFieldValue) => {
    const val = value === 'A' ? 'I' : 'A';
    setFieldValue('terminalStatus', val);
    setStatusControl((prev) => ({ ...prev, isOpenStsDlg: false, val }));
  };
  let columnDefs = [
    {
      field: 'agencyCode',
      headerName: 'Agency Code',
    },
    {
      field: 'locationName',
      headerName: 'Location Name',
    },
    {
      field: 'terminalName',
      headerName: 'Name',
    },
    {
      cellRenderer: serviceValueGetter,
      headerName: 'Service Name',
    },
    {
      field: 'terminalId',
      headerName: 'ID',
    },
    {
      field: 'terminalType',
      headerName: 'Type',
    },

    {
      field: 'terminalStatus',
      headerName: 'Status',
      valueGetter: (params) => (params?.data?.terminalStatus === 'A' ? 'Active' : 'Inactive'),
    },
    {
      field: 'operatingStatus',
      headerName: 'Operating Status',
      valueGetter: (params) => (params?.data?.operatingStatus === 'Y' ? 'Yes' : 'No'),
    },
    {
      field: 'isBusy',
      headerName: 'Busy',
      valueGetter: (params) => (params?.data?.isBusy === 'Y' ? 'Yes' : 'No'),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
    },
    {
      field: 'createdDatetime',
      headerName: 'Created DateTime',
      type: 'dataTimeFormat',
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Modified By',
    },
    {
      field: 'lastUpdatedDatetime',
      headerName: 'Modified DateTime',
      type: 'dataTimeFormat',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      filter: false,
      resizable: false,
      editable: false,
      sortable: false,
      cellRenderer: ({ data }) => ActionRenderer(data, handleView),
    },
  ];

  columnDefs = permission && permission?.P00220 === true ? columnDefs : columnDefs.slice(0, -1);

  /* Get all data on initial render */
  const getAllApis = async () => {
    await Promise.all([getAllConnectivityDropdown(), getServicesList(), getAllAgencies()]);
  };

  React.useEffect(() => {
    getTerminalsList();
  }, []);

  const handleSubmit = async (values, formik) => {
    console.log(values);
    if (!edit) {
      const data = {
        agencyCode: values.agencyCode,
        locationCode: values.locationCode,
        terminalId: values.terminalId,
        terminalType: values.terminalType,
        terminalStatus: values.terminalStatus,
        operatingStatus: 'N',
        isBusy: 'N',
        serviceCodeList: values.serviceCodeList,
        terminalName: values.terminalName,
      };
      console.log(data);
      const res = await axios.post(jwtServiceConfig.terminalAdd, data);

      if (res.data.responseCode === 0) {
        handleResponse(res);
        setOpen(false);
        setEdit(false);
        setUserValidation((prev) => ({
          ...prev,
          validate: true,
          msg: 'Plese enter valid userID',
        }));
        formik.resetForm();
        getTerminalsList();
      } else {
        handleResponse(res);
      }
    } else {
      /* Validating location for teller */
      if (values?.terminalType === 'TLR') {
        if (values.locationCode !== userValidation?.isUserLocation) {
          formik?.setFieldTouched('locationCode', true);
          return formik?.setFieldError('locationCode', 'Teller/Terminal does not belongs to the selected location please select the correct location to proceed');
        }
        formik?.setFieldTouched('locationCode', false);
      }
      const data = {
        id: values.id,
        locationCode: values.locationCode,
        terminalId: values.terminalId,
        terminalType: values.terminalType,
        terminalStatus: values.terminalStatus,
        // operatingStatus: values.operatingStatus,
        // isBusy: values.isBusy,
        serviceCodeList: values.serviceCodeList,
        terminalName: values.terminalName,
        agencyCode: values.agencyCode,
      };
      console.log(data);

      const res = await axios.post(jwtServiceConfig.terminalEdit, data);

      if (res.data.responseCode === 0) {
        handleResponse(res);
        setOpen(false);
        setEdit(false);
        formik.resetForm();
        getTerminalsList();
      } else {
        handleResponse(res);
      }
    }
    return values;
  };

  const getTellerDetails = async (values, setFieldValue, props) => {
    if (values.terminalId === '') {
      props.setFieldTouched('terminalId', true);
      return props.setFieldError('terminalId', 'Please enter the user ID');
    }
    if (values.locationCode === '' || values.agencyCode === '') {
      props.setFieldTouched('terminalId', true);
      return props.setFieldError('terminalId', 'Please select the agency code/location code');
    }
    await axios
      .post(jwtServiceConfig.userGetUserCodesOrId, {
        userCode: values.terminalId,
        agencyCode: values.agencyCode,
        locationCode: values.locationCode, // O
      })
      .then((res) => {
        if (res.data.responseCode === 0) {
          props.setFieldError('terminalId', '');
          props.setFieldError('terminalName', '');
          setUserValidation((prev) => ({
            ...prev,
            validate: false,
            msg: '',
          }));
        } else if (res.data.responseCode === 155) {
          props.setFieldError('terminalId', res?.data?.errors[0]?.message);
          setUserValidation((prev) => ({
            ...prev,
            validate: true,
            msg: res?.data?.errors[0]?.message,
          }));
        } else {
          handleResponse(res);
        }
      });
    return values;
  };

  const tellerDropdown = [
    {
      label: 'Teller',
      value: 'TLR',
    },
    {
      label: 'Self Service Machine',
      value: 'SSM',
    },
  ];

  const getLocationForAgency = async (e, setFieldValue) => {
    const { value } = e.target;
    if (value) {
      setFieldValue('locationCode', '');
      setFieldValue('agencyCode', value);
      await getAllLocationsList(value);
    }
  };

  const handleCloseTerminalDialog = (values, resetForm) => {
    if (statusChangeMonitor(values, selectedValues, 'terminalStatus') && !confirmCancel && edit) {
      setCnfrmDlgOpen(true);
    } else {
      setOpen(false);
      setEdit(false);
      setSelectedValues([]);
      setUserValidation((prev) => ({
        ...prev,
        validate: true,
        msg: 'Plese enter valid userID',
      }));
      resetForm();
      setConfirmCancel(false);
    }
  };

  return (
    <Paper className="relative flex flex-col flex-auto p-24 prounded-2xl shadow overflow-hidden">
      <Box className="mt-4 ml-8 flex flex-row justify-between">
        <Box>
          <Typography className="text-3xl font-semibold tracking-tight leading-8">Teller & Terminal Configuration</Typography>
          <Box>
            <Box>
              <span className="text-md">Queue Management &gt; Teller & Terminal Configuration</span>
            </Box>
          </Box>
        </Box>
        {permission?.P00219 && (
          <Box className="mt-24">
            <MuiButton
              type="basic"
              onClick={async () => {
                setLocationValues([]);
                await getAllApis();
                setEdit(false);
                setOpen(true);
              }}
            >
              Add
            </MuiButton>
          </Box>
        )}
      </Box>

      <Box className="mt-16">
        <div style={{ width: '100%' }} className="ag-theme-balham">
          <AgGrid rowData={rowData} columnDefs={columnDefs} overlayNoRowsTemplate="No terminal configured, please configure terminal" />
        </div>
      </Box>
      <Formik
        initialValues={edit ? selectedValues : initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validate={(value) => {
          /* Set custom validation message for teller */
          const errors = {};
          if (userValidation?.validate === true && value.terminalType === 'TLR') {
            errors.terminalId = userValidation.msg;
          }
          return errors;
        }}
      >
        {({ values, setFieldValue, resetForm, ...props }) => (
          <>
            <MuiFormDialog open={open} dialogTitle={dialogTitle} onClose={() => handleCloseTerminalDialog(values, resetForm)}>
              <GridContainer spacing={2}>
                <GridItem xs={12}>
                  <FormikController control="select"
                    name="agencyCode" label="Agency" onChange={(e) => getLocationForAgency(e, setFieldValue, resetForm)}  required disabled={edit} >
                    {agency?.map((option) => {
                      return (
                        <MenuItem key={option?.agencyCode} value={option?.agencyCode}>
                          {option?.agencyName}
                        </MenuItem>
                      );
                    })}
                  </FormikController>
                </GridItem>
                <GridItem xs={12}>
                  <FormikController control="select" name="locationCode" label="Select Location"
                    required
                    disabled={locationValues.length === 0}
                  >
                    {locationValues?.map((option) => (
                      <MenuItem key={option?.locationCode} value={option?.locationCode}>
                        {`${option?.locationName} - ${option?.locationCode}`}
                      </MenuItem>
                    ))}
                  </FormikController>
                </GridItem>
                <GridItem xs={12}>
                  <FormikController
                    control="select"
                    name="terminalType"
                    label="Teller / Terminal Type"
                    required
                    disabled={edit}
                    onChange={async (e) => {
                      await setFieldValue('terminalType', e.target.value);
                      await setFieldValue('terminalName', '');
                      await setFieldValue('terminalId', '');
                      props.setFieldError('terminalId', '');
                    }}
                  >
                    {tellerDropdown?.map((item) => (
                      <MenuItem key={item?.value} value={item?.value}>
                        {`${item?.value} - ${item?.label}`}
                      </MenuItem>
                    ))}
                  </FormikController>
                </GridItem>
                <GridItem xs={12}>
                  <FormikController
                    control="select"
                    name="serviceCodeList"
                    label="Select Services"
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => {
                        const tempValue = selected?.map((item) => {
                          const serviceNameValue = servicesValues?.find((service) => service?.serviceCode === item);
                          return serviceNameValue;
                        });
                        return (
                          <Stack gap={1} direction="row" flexWrap="wrap">
                            {tempValue?.map((item) => (
                              <Chip
                                key={item?.serviceCode}
                                label={item?.serviceName}
                                onDelete={() =>
                                  setFieldValue(
                                    'serviceCodeList',
                                    values?.serviceCodeList?.filter((srv) => srv !== item?.serviceCode)
                                  )
                                }
                                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                              />
                            ))}
                          </Stack>
                        );
                      },
                    }}
                  >
                    {servicesValues?.map((option) => (
                      <MenuItem key={option?.serviceCode} value={option?.serviceCode}>
                        {option?.serviceName}
                      </MenuItem>
                    ))}
                  </FormikController>
                </GridItem>
                {values?.terminalType === 'TLR' && (
                  <GridItem xs={12}>
                    <FormikController
                      control="textField"
                      name="terminalId"
                      label="Teller ID"
                      required
                      onChange={(e) => props?.handleChange(e)}
                      disabled={edit || values?.terminalType === ''}
                      InputProps={
                        values.terminalType === 'TLR' && {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => getTellerDetails(values, setFieldValue, props)} disabled={edit}>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      }
                    />
                  </GridItem>
                )}
                {values?.terminalType === 'SSM' && !edit && (
                  <GridItem xs={12}>
                    <Field name="terminalId">
                      {() => (
                        <Autocomplete
                          disabled={edit || values?.terminalType === ''}
                          options={connectivityDropdown?.length > 0 ? connectivityDropdown : []}
                          getOptionLabel={(option) => `${option.connectivityId} - ${option.connectivityName}`}
                          onChange={(_, value) => {
                            console.log(value);
                            if (value) {
                              return setFieldValue('terminalId', value?.connectivityId);
                            }
                            return setFieldValue('terminalId', '');
                          }}
                          inputvalue=""
                          onBlur={props?.handleBlur}
                          clearOnBlur
                          renderInput={(params) => (
                            <Field
                              as={TextField}
                              InputProps={params.InputProps}
                              InputLabelProps={params.InputLabelProps}
                              inputProps={params.inputProps}
                              label="Terminal ID"
                              name="terminalId"
                              fullWidth
                              autoComplete="off"
                              onBlur={props?.handleBlur}
                              onChange={(e) => props?.handleChange(e)}
                              required={!edit}
                              disabled={values?.terminalType === ''}
                              variant="outlined"
                              helperText={<ErrorMessage name="terminalId" />}
                              error={Boolean(props.errors.terminalId && props.touched.terminalId)}
                            />
                          )}
                        />
                      )}
                    </Field>
                  </GridItem>
                )}
                {selectedValues?.terminalType === 'SSM' && edit && (
                  <GridItem xs={12}>
                    <FormikController control="textField" name="terminalId" label="Terminal ID" disabled={edit} value={`${values.terminalId} - ${values.terminalName}`}/>
                    
                   
                  </GridItem>
                )}
                {values?.terminalType && (
                  <GridItem xs={12}>
                    <FormikController control="textField" name="terminalName" required label={values.terminalType === 'TLR' ? 'Teller Name' : 'Terminal Name'} inputProps={{ maxLength: 100 }} />
                  </GridItem>
                )}
                {permission?.P00221 && (
                  <GridItem xs={12}>
                    <FormikController
                      control="switch"
                      name="terminalStatus"
                      label="Status"
                      checked={values.terminalStatus === 'A'}
                      onChange={(e) => {
                        handleSetStatus(e, setFieldValue)
                        // const newStatus = e.target.checked ? 'A' : 'I';
                        // setFieldValue('terminalStatus', newStatus);
                        // setSelectedValues((prev) => ({ ...prev, changedTerminalStatus: newStatus }));
                        // setCnfrmDlgOpen(true);
                        // getActiveOrInActive(values.terminalStatus);
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </MuiFormDialog>
            <MuiDialog open={statusControl?.isOpenStsDlg && edit} width="500px">
              <DialogTitle className="flex justify-between">
                <Typography className="text-xl font-semibold tracking-tight leading-8">Change Teller / Terminal status</Typography>
              </DialogTitle>
              <DialogContent>
                <Box className="my-12">
                  <Typography className="mb-12 font-500 text-lg">Are you sure want to {values?.terminalStatus === 'A' ? 'enable' : 'disable'} the Teller / Terminal ?</Typography>
                </Box>
              </DialogContent>
              <DialogActions className="flex justify-end">
                <MuiButton type="cancel-btn" onClick={() => handleCloseStsDlg(values?.terminalStatus, setFieldValue)}>
                  No
                </MuiButton>
                <MuiButton type="basic" onClick={handleChangeStatus}>
                  Yes
                </MuiButton>
              </DialogActions>
            </MuiDialog>
          </>

        )}
      </Formik>
      <ConfirmationDialog
        openModal={cnfrmDlgOpen}
        closeModal={() => {
          setCnfrmDlgOpen(false);
        }}
        handleSubmitToCloseDialog={() => {
          setConfirmCancel(true);
          setCnfrmDlgOpen(false);
        }}
      />
    </Paper>
  );
};
export default TerminalTable;
