import { useState, useEffect } from 'react';
import { Box, Paper, Typography, Chip } from '@mui/material';
import { handleDateTime } from 'app/shared-components/page-utils/pageUtils';
import { Formik } from 'formik';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { selectUser } from 'app/store/userSlice';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';

import GridContainer from 'app/shared-components/mui/grid/GridContainer';
import GridItem from 'app/shared-components/mui/grid/GridItem';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import { handleResponse } from 'app/shared-components/index';
import FormikController from 'app/shared-components/formik-controller/FormikController';
import MuiFormDialog from 'app/shared-components/mui/dialog/MuiFormDialog';
import { initialValuesAssignedQueuePage, validationSchemaAssignedQueuePage } from '../helperFuntionQueueManagement';

/** Teller Service Page
 * The teller service page has the option to view the assigned queue for the teller.
 * Teller have option to serve or reschdule the queue number.
 * There is an option to start/stop the queue assignment for himself.
 */

const AssignedQueuePage = () => {
  const [pageControl, setPageControl] = useState({
    isOpenStopModal: false,
    selectedValues: '',
  });
  const [showDetails, setShowDetails] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [assisgnedQueueDetails, setAssisgnedQueueDetails] = useState(undefined);
  const user = useSelector(selectUser);
  const queuePageDialogTitle = 'Comments';

  const getQueueDetails = async () => {
    await axios
      .post(jwtServiceConfig.terminalAssignedQueueForTeller, {
        terminalId: user.userCode,
        locationCode: user.locationCode,
      })
      .then((res) => {
        console.log(res);
        if (res.data.responseCode === 0) {
          setRowData(res.data.data.services);
          setAssisgnedQueueDetails(res?.data?.data);
          setShowDetails(true);
          if (res?.data?.data?.assignedQueue?.qnumServeStatus === 3) {
            setIsProcess(true);
          }
        } else {
          handleResponse(res);
        }
      });
  };

  useEffect(() => {
    getQueueDetails();
  }, []);

  const handleChangeTerminalStatus = async (value, { resetForm }) => {
    const data = {
      locationCode: user.locationCode,
      terminalId: user.userCode,
      remarks: value.remarks,
      operatingStatus: assisgnedQueueDetails?.terminalStatus === 'Y' ? 'N' : 'Y',
    };
    console.log(data);
    await axios.post(jwtServiceConfig.terminalTerminalStatusUpdate, data).then((res) => {
      if (res.data.responseCode === 0) {
        handleResponse(res);
        setPageControl((prev) => ({ ...prev, isOpenStopModal: false }));
        resetForm();
        getQueueDetails();
      } else {
        handleResponse(res);
        getQueueDetails();
      }
    });
  };

  const columnDefs = [
    {
      field: 'serviceName',
      headerName: 'My Services',
    },
    {
      field: 'queueCount',
      headerName: 'Waiting Queue Count',
    },
    {
      field: 'terminalCount',
      headerName: 'Active Terminal Count',
    },
  ];

  const moveQueueStatusAndExpendDetails = async () => {
    await axios
      .post(jwtServiceConfig.queueUpdateQueueByTeller, {
        queueNumber: assisgnedQueueDetails?.assignedQueue?.queueNumber, //
        qnumServeStatus: 3, // 3
        terminalId: user.userCode,
        // isBusy: "Y",
      })
      .then((res) => {
        console.log(res);
        if (res.data.responseCode === 0) {
          setIsProcess(true);
        } else {
          getQueueDetails();
          handleResponse(res);
        }
      });
  };

  const closeQueue = async () => {
    const data = {
      queueNumber: assisgnedQueueDetails?.assignedQueue.queueNumber, //
      terminalId: user.userCode,
    };
    console.log(data);
    await axios.post(jwtServiceConfig.queueRescheduleQueue, data).then((res) => {
      if (res.data.responseCode === 0) {
        handleResponse(res);
        setShowDetails(false);
        setIsProcess(false);
        setAssisgnedQueueDetails(undefined);
        getQueueDetails();
      } else {
        handleResponse(res);
      }
    });
  };
  const closeQueueComplete = async (val) => {
    const data = {
      queueNumber: assisgnedQueueDetails?.assignedQueue.queueNumber, //
      qnumServeStatus: val,
      terminalId: user.userCode,
    };
    console.log(data);
    await axios.post(jwtServiceConfig.queueUpdateQueueByTeller, data).then((res) => {
      if (res.data.responseCode === 0) {
        handleResponse(res);
        setShowDetails(false);
        setIsProcess(false);
        setAssisgnedQueueDetails(undefined);
        getQueueDetails();
      } else {
        handleResponse(res);
      }
    });
  };
  const rescheduleQueue = async (val) => {
    const data = {
      queueNumber: assisgnedQueueDetails?.assignedQueue.queueNumber,
      qnumServeStatus: val, //
      terminalId: user.userCode,
    };
    console.log(data);
    await axios.post(jwtServiceConfig.queueUpdateQueueByTeller, data).then((res) => {
      if (res.data.responseCode === 0) {
        handleResponse(res);
        setShowDetails(false);
        setIsProcess(false);
        setAssisgnedQueueDetails(undefined);
        getQueueDetails();
      } else {
        handleResponse(res);
        getQueueDetails();
      }
    });
  };

  const handleCloseStopModal = (resetForm) => {
    setPageControl((prev) => ({ ...prev, isOpenStopModal: false }));
    resetForm();
  };

  return (
    <>
      <Paper className="relative flex flex-col flex-auto p-24 prounded-2xl shadow overflow-hidden">
        <Box className="mt-4 ml-8">
          <Typography className="text-3xl font-semibold tracking-tight leading-8">Teller Service</Typography>
          <Box>
            <Box>
              <span className="text-md">Queue Management &gt; Teller Service</span>
            </Box>
          </Box>
        </Box>
        {showDetails && (
          <>
            <Paper className="prounded-2xl shadow overflow-hidden my-8">
              <div className="flex flex-row-reverse justify-between m-24 mb-0">
                <div className="ml-8">
                  <Chip size="small" className="font-medium text-sm" label={handleDateTime(new Date(), 'D')} />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-start mt-24 mx-24 my-24">
                  {/* <div className="grid grid-cols-1 sm:grid-cols-4 gap-32 sm:gap-48"> */}
                  <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-32 sm:gap-48 lg:gap-32">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <div className="font-medium text-secondary leading-5">Queue Number</div>
                      </div>
                      <div className="flex items-start mt-8">
                        <div className="text-2xl font-bold tracking-tight leading-none">{assisgnedQueueDetails?.assignedQueue?.queueNumber ?? 'N/A'}</div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <div className="font-medium text-secondary leading-5">Staging Reference Number</div>
                      </div>
                      <div className="flex items-start mt-8">
                        <div className="text-2xl font-bold tracking-tight leading-none">{assisgnedQueueDetails?.assignedQueue?.stagingRefNum ?? 'N/A'}</div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <div className="font-medium text-secondary leading-5">Requested Service</div>
                      </div>
                      <div className="flex items-start mt-8">
                        <div className="text-2xl font-bold tracking-tight leading-none">{assisgnedQueueDetails?.requestedService ?? 'N/A'}</div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <div className="font-medium text-secondary leading-5">Created DateTime</div>
                      </div>
                      <div className="flex items-start mt-8">
                        <div className="text-2xl font-bold tracking-tight leading-none">
                          {assisgnedQueueDetails?.assignedQueue?.queueNumberGenerationDatetime ? handleDateTime(assisgnedQueueDetails?.assignedQueue?.queueNumberGenerationDatetime) : 'N/A'}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <div className="font-medium text-secondary leading-5">Assigned DateTime</div>
                      </div>
                      <div className="flex items-start mt-8">
                        <div className="text-2xl font-bold tracking-tight leading-none">
                          {assisgnedQueueDetails?.assignedQueue?.queueNumberAssignmentDatetime ? handleDateTime(assisgnedQueueDetails?.assignedQueue?.queueNumberAssignmentDatetime) : 'N/A'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-12 flex items-end">
                  {!isProcess && (
                    <div className="flex">
                      <MuiButton type="basic" disabled={!assisgnedQueueDetails?.assignedQueue} onClick={() => rescheduleQueue(4)}>
                        Skip
                      </MuiButton>
                      <MuiButton type="basic" className="ml-8" disabled={!assisgnedQueueDetails?.assignedQueue} onClick={() => moveQueueStatusAndExpendDetails()}>
                        Serve
                      </MuiButton>
                    </div>
                  )}
                </div>
              </div>
              {isProcess && (
                <div className="my-12 mx-24">
                  <div className="my-12">
                    <div className="my-8">
                      <div className="flex justify-end">
                        <MuiButton type="basic" className="bg-red" onClick={() => closeQueue()}>
                          Reschedule
                        </MuiButton>
                        <MuiButton type="basic" className="ml-8" onClick={() => closeQueueComplete(2)}>
                          Complete
                        </MuiButton>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Paper>
            <div className="my-12 p-12 sm:w-full lg:w-2/4 md:w-2/4 w-full">
              <AgGrid rowData={rowData} columnDefs={columnDefs} overlayNoRowsTemplate="No Data Found" />
              <div className="flex flex-row-reverse p-12">
                <MuiButton type="basic" className="bg-red" disabled={isProcess} onClick={() => setPageControl((prev) => ({ ...prev, isOpenStopModal: true }))}>
                  {assisgnedQueueDetails?.terminalStatus === 'Y' ? 'Stop' : 'Start'}
                </MuiButton>
              </div>
            </div>
          </>
        )}
      </Paper>

      <Formik initialValues={initialValuesAssignedQueuePage} validationSchema={validationSchemaAssignedQueuePage} onSubmit={handleChangeTerminalStatus} validateOnBlur={false}>
        {({ resetForm }) => (
          <MuiFormDialog open={pageControl?.isOpenStopModal} dialogTitle={queuePageDialogTitle} onClose={() => handleCloseStopModal(resetForm)}>
            <GridContainer spacing={2}>
              <GridItem xs={12}>
                <FormikController control="textField" name="remarks" label="Comments" required inputProps={{ maxLength: 250 }} />
              </GridItem>
            </GridContainer>
          </MuiFormDialog>
        )}
      </Formik>
    </>
  );
};

export default AssignedQueuePage;
