import * as Yup from 'yup';

// ---------------------------------- Location Page ---------------------------- //

export const validationSchemaLocationPage = Yup.object().shape({
  agencyCode: Yup.string().required('Required'),
  locationName: Yup.string().required('Required'),
  locationCode: Yup.string().required('Required'),
});

export const initialValuesLocationPage = { agencyCode: '', locationName: '', locationCode: '', isDeleted: 'Y' };
// ---------------X-X-X------------ Location Page End -----------X-X-X---------- //
//
// ---------------------------------- Service Page ---------------------------- //
export const validationSchemaServicePage = Yup.object().shape({
  serviceCode: Yup.number().required('Required').typeError('Service code must be a number').min(1, 'Min value 1.').max(99, 'Max value 99.'),
  serviceName: Yup.string().required('Required').trim(),
  // serviceStatus: Yup.string().required("Required")
});

export const initialValuesServicePage = {
  serviceCode: '',
  serviceName: '',
  serviceStatus: 'I',
};
// ---------------X-X-X------------ Service Page End -----------X-X-X---------- //

// ---------------------------------- Assigned Queue Page ---------------------------- //

export const validationSchemaAssignedQueuePage = Yup.object().shape({
  remarks: Yup.string().required('Required').trim(),
});

export const initialValuesAssignedQueuePage = { remarks: '' };
// ---------------X-X-X------------ Assigned Queue Page End -----------X-X-X---------- //

// ---------------------------------- Assigned Queue Page ---------------------------- //

export const validationSchemaQueueHistoryPage = Yup.object().shape({
  locationCode: Yup.string().required('Required'),
  agencyCode: Yup.string().required('Required'),
});

export const initialValuesQueueHistoryPage = { locationCode: '', agencyCode: '' };
// ---------------X-X-X------------ Assigned Queue Page End -----------X-X-X---------- //

// ---------------------------------- To monitor only when the status changes ---------------------------- //
export const statusChangeMonitor = (values, selectedValues, statusKey) => {
  const { [statusKey]: currentStatus, ...currentFields } = values;
  const { [statusKey]: initialStatus, ...initialFields } = selectedValues;

  const isStatusChanged = currentStatus !== initialStatus;
  const areOtherFieldsUnchanged = Object.keys(currentFields).every((key) => currentFields[key] === initialFields[key]);

  return isStatusChanged && areOtherFieldsUnchanged;
};
