import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, getIn } from 'formik';
import { Link } from 'react-router-dom';
//
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
//
import { useDispatch } from 'react-redux';
import { openDialog } from 'app/store/fuse/dialogSlice';
import axios from 'axios';
import FormikController from 'app/shared-components/formik-controller/FormikController';
//

function ForgotPasswordPage() {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    userID: Yup.string().required('Required'),
  });

  const handleSubmit = async (values, props) => {
    const res = await axios.post(jwtServiceConfig.forgotPassword, {
      loginUserName: values.userID,
    });
    console.log(res);
    if (res.data.responseCode === 0) {
      dispatch(
        openDialog({
          response: {
            title: res.data.message,
            message: res.data.data.message,
          },
        })
      );
      // setTimeout(() => navigate('/sign-in'), 2000);
    } else if (res?.data?.responseCode === 155) {
      dispatch(
        openDialog({
          response: {
            error: true,
            title: 'Alert',
            message: 'If an account with this email/username exists, you will receive a password reset link shortly.',
          },
        })
      );
    } else {
      dispatch(
        openDialog({
          response: {
            error: true,
            title: res.data.message,
            message: res.data.errors[0].message,
          },
        })
      );
    }

    props.resetForm();
  };
  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">Forgot Password?</Typography>
          <div className="flex items-baseline mt-2 font-medium">
            <Typography>Fill the form to reset your password</Typography>
          </div>
          <Formik initialValues={{ userID: '' }} onSubmit={handleSubmit} enableReinitialize validationSchema={validationSchema} validateOnBlur={false}>
            {({ errors, touched, setFieldValue }) => (
              <Form className="flex flex-col justify-center w-full mt-32" noValidate>
                <Grid>
                  <FormikController control="textField" name="userID" label="Username" required className="mb-24" onChange={(evnt) => setFieldValue(evnt.target.name, evnt.target.value)} />
                </Grid>
                <Button variant="contained" color="secondary" className=" w-full mt-4" aria-label="Register" type="submit" size="large">
                  Send reset link
                </Button>
                <Typography className="mt-32 text-md font-medium" color="text.secondary">
                  <span>Return to</span>
                  <Link className="ml-4" to="/sign-in">
                    sign in
                  </Link>
                </Typography>
              </Form>
            )}
          </Formik>
        </div>
      </Paper>
    </div>
  );
}

export default ForgotPasswordPage;
