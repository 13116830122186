import { useNavigate } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';

const url = `/module/bank-employee-services/txn-receipt-success`;
const publicUrl = `/module/public-payment-management/txn-receipt-success`;

const NavigatePrntAndDownload = ({ data }) => {
  console.log('prntdwlddata', data);
  const navigate = useNavigate();

  const navigateTo = (navigationURL, state) => {
    const redirectUrl = state?.redirectUrl === 'public' ? publicUrl : navigationURL;
    navigate(redirectUrl, {
      state: { data: state },
    });
  };

  const handlePrint = (payload, isType) => {
    const paylod = { ...payload, isType };
    navigateTo(url, paylod);
  };

  const handleDownload = (payload, isType) => {
    const paylod = { ...payload, isType };
    navigateTo(url, paylod);
  };

  return (
    <>
      <PrintIcon sx={{ fontSize: '18px' }} onClick={() => handlePrint(data, 'prnt')} />
      <DownloadIcon sx={{ fontSize: '18px', ml: '12px' }} onClick={() => handleDownload(data, 'dwld')} />
    </>
  );
};

export default NavigatePrntAndDownload;
