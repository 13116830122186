import { Box, FormLabel, Card, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import ActionRendererComponent from 'app/shared-components/action-render/ActionRendererComponent';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { handleResponse } from 'app/shared-components/index';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import MuiButton from 'app/shared-components/mui/button/MuiButton';

const BURComBankTransfer = ({ rowData, setRowData }) => {
  const downloadCSVFile = (img, reqId) => {
    const linkSource = `data:application/csv;base64,${img}`;
    const downloadLink = document.createElement('a');
    const fileName = `${reqId}`;
    downloadLink.href = linkSource;
    downloadLink.target = '_blank';
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const viewReceipt = async (value) => {
    const data = {
      fileNameWithPath: `${value.outputFilePath}${value.outputFileName}`,
    };
    console.log(data);
    const res = await axios.post(jwtServiceConfig.fpViewFileBase64, data);
    console.log(res);
    if (res.data.responseCode === 0) {
      const img = res?.data?.data?.base64String;
      const reqId = value?.outputFileName;
      downloadCSVFile(img, reqId);
    } else {
      handleResponse(res);
    }
  };
  const handleView = (data) => {
    viewReceipt(data);
  };

  const columnDefsT24BISSFileBURS = [
    { field: 'id', headerName: 'Job ID' },
    { field: 'fileName', headerName: 'T24/BISS File' },
    { field: 'createdBy', headerName: 'Uploaded By' },
    {
      field: 'createdDateTime',
      headerName: 'DateTime',
      type: 'dataTimeFormat',
    },
    { field: 'jobStatus', headerName: 'Status' },
    { field: 'outputFileName', headerName: 'Output File Name' },
    {
      field: 'report',
      headerName: 'Download',
      cellRenderer: ({ data }) => ActionRendererComponent('isViewFile', data, handleView),
    },
  ];
  async function getAllUploadedFileDetails() {
    const res = await axios.post(jwtServiceConfig.fpGetAllUploadedFileDetails, {
      jobType: 'BUR_COM',
    });
    console.log(res.data);
    if (res.data.responseCode === 0) {
      setRowData(res.data.data);
    } else {
      handleResponse(res);
    }
  }
  const handleSubmitT24BISSFileBURS = async (values, resetForm) => {
    const formData = new FormData();
    formData.append(
      'jsonData',
      JSON.stringify({
        jobType: 'BUR_COM',
        fileDefinitions: [{ fileName: values?.T24BISSFile.name, definitionName: 'T24' }],
      })
    );
    formData.append('fileList', values?.T24BISSFile);
    const res = await axios.post(jwtServiceConfig.fpUploadMultipleFile, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(res?.data);
    if (res.data.responseCode === 0) {
      handleResponse(res);
      getAllUploadedFileDetails();
      resetForm();
    } else {
      handleResponse(res);
    }
  };

  //   const validationSchema = Yup.object().shape({
  //     T24BISSFile: Yup.mixed().required("Required"),
  //     // .test(
  //     //   "is-valid-size",
  //     //   "Maximum allowed size is 10 MB",
  //     //   (value) => value && value.size <= 10000000
  //     // ),
  //   });

  return (
    <>
      <Box className=" text-sm">BURS Consolidation Transfers from Commercial Banks File Reconciliation</Box>
      <Box>
        <Formik
          initialValues={{ T24BISSFile: undefined }}
          onSubmit={handleSubmitT24BISSFileBURS}
          validateOnBlur={false}
          //   validationSchema={validationSchema}
        >
          {({ values, resetForm, setFieldValue }) => (
            <Box className="my-4 mr-8">
              <Box className="mt-8 ">
                <Form noValidate>
                  <Box className="flex justify-between my-8 pr-16 flex-col sm:flex-row md:flex-row sm:px-8 md:px-8">
                    <Card className="flex flex-col items-start w-full h-[120px] p-24 rounded-none overflow-auto ">
                      <Box className="flex-column my-8">
                        <Box className="my-8">
                          <FormLabel>BURS Commercial Bank Transfer File </FormLabel>
                        </Box>
                        <div className="flex">
                          <div className="flex items-center">
                            <div>
                              <span className=" text-sm ">
                                {values?.T24BISSFile ? values?.T24BISSFile?.name : 'Select File'}
                                {values?.T24BISSFile && (
                                  <CloseIcon
                                    onClick={() => {
                                      setFieldValue('T24BISSFile', null);
                                    }}
                                    style={{ marginLeft: '8px', fontSize: '16px' }}
                                  />
                                )}
                              </span>
                            </div>
                            <div className="mx-8">
                              <MuiButton type="basic" component="label">
                                <Typography className="text-13 font-medium tracking-tight">Browse</Typography>

                                <input
                                  type="file"
                                  name="T24BISSFile"
                                  required
                                  hidden
                                  accept=".csv"
                                  onChange={(event) => {
                                    setFieldValue('T24BISSFile', event.currentTarget.files[0]);
                                  }}
                                />
                              </MuiButton>
                              {/* {touched.T24BISSFile && errors.T24BISSFile && (
                              <div className="text-sm ml-8 mt-16" style={{ color: "#EE1D30" }}>
                                {errors.T24BISSFile}
                              </div>
                            )} */}
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Card>

                    <Box>
                      <Box className="flex justify-end my-20 px-8">
                        {/* <Box>
                            <Button variant="outlined" size="small">
                              Cancel
                            </Button>
                          </Box> */}
                        <Box>
                          <MuiButton type="basic" sx={{ marginLeft: '10px' }} disabled={values?.T24BISSFile === undefined} onClick={() => handleSubmitT24BISSFileBURS(values, resetForm)}>
                            Reconcile
                          </MuiButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
      <Box className="mt-32">
        <AgGrid rowData={rowData} columnDefs={columnDefsT24BISSFileBURS} />
      </Box>

      {/* <CustomDialogBox
            open={open}
            onClose={() => setOpen(false)}
            width={"800px"}
            children={
              <>
                <DialogTitle>File Reconciliation</DialogTitle>
                <DialogContent>
                  <Box className="flex flex-row">
                    <Box className="w-1/3">
                      <Card className="flex flex-col items-start w-full h-[240px] p-24 rounded-lg shadow rounded-lg hover:shadow-xl transition-shadow duration-150 ease-in-out scroll-y-auto">
                        <div className="flex flex-col w-full">
                          <Typography className="text-lg font-bold">Processing Files</Typography>
                        </div>
                        <div className="my-40 mx-80">
                          {loader && <CircularProgress color="inherit" />}
                        </div>
                      </Card>
                    </Box>
                    <Box className="w-2/3">
                      <Card className="flex flex-col items-start w-full h-[240px] p-24 rounded-lg shadow rounded-lg hover:shadow-xl transition-shadow duration-150 ease-in-out scroll-y-auto">
                        <div className="flex flex-col w-full">
                          <Typography className="text-lg font-bold">Process Log</Typography>
                        </div>
                        <div className="mt-16 space-y-8">
                          <div className="flex scroll-auto">
                            <FuseSvgIcon className="text-green-600" size={20}>
                              heroicons-solid:check
                            </FuseSvgIcon>
                            <Typography className="ml-2 leading-5">Reconciliation complete</Typography>
                          </div>
                        </div>
    
                        <div className="flex flex-col mt-8">
                          <div className="mt-16 space-y-8">
                            <div className="flex scroll-auto">
                              <Typography className="ml-2 leading-5">1500 record reconciled</Typography>
                            </div>
                            <div className="flex scroll-auto">
                              <Typography className="ml-2 leading-5">950 record matched</Typography>
                            </div>
                            <div className="flex scroll-auto">
                              <Typography className="ml-2 leading-5">550 record mismatched</Typography>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row justify-between">
                          <div className="mt-24">
                            <Button
                              sx={{ fontSize: "10px" }}
                              size="small"
                              onClick={() => setOpen(false)}
                              className="bg-black text-white hover:cursor-pointer hover:text-black "
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="mt-24 mx-48">
                            <Button
                              sx={{ fontSize: "10px" }}
                              size="small"
                              className="bg-black text-white hover:cursor-pointer hover:text-black "
                            >
                              Excel
                            </Button>
                            <Button
                              sx={{ fontSize: "10px", marginLeft: "10px" }}
                              size="small"
                              className="bg-black text-white hover:cursor-pointer hover:text-black "
                            >
                              PDF
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Box>
                  </Box>
                </DialogContent>
              </>
            }
          /> */}
    </>
  );
};
export default BURComBankTransfer;
