/* Permission/child permission for each page to check the login response permission */

const permissions = {
  usersManangement: {
    userPage: {
      parentId: 'P00137',
      children: ['P00039', 'P00038', 'P00040', 'P00041', 'P00042', 'P00043', 'P00034', 'P00035', 'P00037', 'P00036', 'P00184', 'P00271'],
    },
    rolePage: { parentId: 'P00136', children: ['P00022', 'P00023', 'P00024', 'P00185', 'P00278'] },
    permissionPage: { parentId: 'P00134', children: ['P00044', 'P00045'] },
    importUsers: { parentId: 'P00272', children: ['P00273'] },
  },
  queueManagement: {
    locationPage: { parentId: 'P00062', children: ['P00215'] },
    servicePage: { parentId: 'P00063', children: ['P00216', 'P00217', 'P00218'] },
    terminalPage: { parentId: 'P00064', children: ['P00219', 'P00220', 'P00221'] },
  },
  collectionPage: {
    parentId: 'P00025',
    children: ['P00250'],
  },
  masterManagement: {
    countryPage: { parentId: 'P00055', children: ['P00186', 'P00187', 'P00253'] },
    statePage: { parentId: 'P00051', children: ['P00188', 'P00189', 'P00255'] },
    cityPage: { parentId: 'P00052', children: ['P00190', 'P00191', 'P00254'] },
    commercialBankPage: { parentId: 'P00053', children: ['P00192', 'P00193', 'P00257'] },
    agencyPage: { parentId: 'P00056', children: ['P00194', 'P00195', 'P00266'] },
    locationOfficePage: { parentId: 'P00054', children: ['P00196', 'P00197', 'P00256'] },
    cbsAccountListPage: { parentId: 'P00057', children: ['P00198', 'P00211', 'P00213', 'P00259'] },
    channelConnectivityPage: {
      parentId: 'P00059',
      children: ['P00199', 'P00212', 'P00214', 'P00222', 'P00223', 'P00224', 'P00258', 'P00260'],
    },
    transactionTypePage: { parentId: 'P00172', children: ['P00200', 'P00201', 'P00261'] },
    revenueCodePage: { parentId: 'P00170', children: ['P00202', 'P00203', 'P00204', 'P00262'] },
    revenueCodeGroupPage: {
      parentId: 'P00171',
      children: ['P00205', 'P00206', 'P00207', 'P00208', 'P00263'],
    },
    paymentCategoryPage: {
      parentId: 'P00060',
      children: ['P00209', 'P00210', 'P00264'],
    },
    userLimitManagementPage: {
      parentId: 'P00247',
      children: ['P00248', 'P00249'],
    },
    notificationSettings: {
      parentId: 'P00268',
      children: ['P00269', 'P00270'],
    },
    settings: {
      parentId: 'P00274',
      children: ['P00275', 'P00277'],
    },
  },
  report: {
    customerPaymentReportPage: {
      parentId: 'P00122',
      children: ['P00104', 'P00183', 'P00102', 'P00101'],
    },
    bundleReportPage: { parentId: 'P00121', children: ['P00109', 'P00112'] },
    bundleAcceptanceReportPage: {
      parentId: 'P00119',
      children: ['P00114', 'P00116'],
    },
    consolidationReportPage: {
      parentId: 'P00120',
      children: ['P00131', 'P00132'],
    },
    assignConsolidationReportPage: {
      parentId: 'P00225',
      children: [],
    },
    dailyPaymentReportPage: {
      parentId: 'P00126',
      children: ['P00140', 'P00141', 'P00139'],
    },
    foreignExchangeReportPage: {
      parentId: 'P00127',
      children: ['P00149', 'P00151'],
    },
    eftReportPage: {
      parentId: 'P00125',
      children: ['P00154', 'P00153', 'P00155', 'P00156'],
    },
    mutilatedNotesReportPage: {
      parentId: 'P00124',
      children: ['P00157', 'P00158', 'P00239'],
    },
    abandonedFundReportPage: {
      parentId: 'P00118',
      children: ['P00161', 'P00162', 'P00240'],
    },
    partialDepositReportPage: {
      parentId: 'P00231',
      children: ['P00232'],
    },
    crmTransactionReportPage: {
      parentId: 'P00233',
      children: ['P00234'],
    },
    coinsTransactionReportPage: {
      parentId: 'P00235',
      children: ['P00236'],
    },
    commercialBankDepositReportPage: {
      parentId: 'P00123',
      children: ['P00159'],
    },

    commercialBankFundTransferReportPage: {
      parentId: 'P00175',
      children: ['P00178'],
    },
  },
};
export { permissions };
