/* eslint-disable class-methods-use-this */
// /* eslint-disable */

import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { jwtServiceConfig } from './jwtServiceConfig';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  constructor() {
    super();
    this.isRefreshing = false;
  }

  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    let requestQueue = [];
    axios.interceptors.request.use(
      (config) => {
        const AxiosConfig = { ...config, timeout: 40000 };
        this.emit('showLoader', true);
        return AxiosConfig;
      },
      (err) => {
        console.log('err', err);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        // Hide the loader once the response is received.
        this.emit('showLoader', false);
        return response;
      },
      (err) => {
        this.emit('showLoader', false);

        return new Promise(async (resolve, reject) => {
          const originalRequest = err.config;
          console.log('err.config', err.config);
          console.log('originalRequest', originalRequest);
          // eslint-disable-next-line no-underscore-dangle
          if (err.config && !err.config._retry) {
            // if you ever get an unauthorized response, logout the user
            if (err.response && err.response.data.status === 404) {
              this.emit('notFoundError', { message: err.code, code: err?.response?.data?.status });
              // Logging out a user if the token has expired.
            } else if (err.config && err.response && err.response.status === 401) {
              if (!this.isRefreshing) {
                console.log('not isRefreshing');
                this.isRefreshing = true;

                try {
                  const newToken = await this.regenerateToken();
                  //  console.log('newToken', newToken);
                  this.isRefreshing = false;

                  axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;

                  requestQueue.forEach((p) => p.resolve(newToken));
                  requestQueue = [];

                  originalRequest.headers.Authorization = `Bearer ${newToken}`;
                  const retryResponse = await axios(err.config);
                  resolve(retryResponse);
                } catch (error) {
                  console.log('not isRefreshing catch');
                  this.isRefreshing = false;

                  requestQueue.forEach((p) => p.reject(error));
                  requestQueue = [];
                  throw error;
                }
              } else {
                console.log('isRefreshing');
                // console.log('err.config', err.config);

                new Promise((resolve, reject) => {
                  requestQueue.push({ resolve, reject });
                })
                  .then((newToken) => {
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axios(err.config);
                  })
                  .then(resolve);
              }
              // if (this.isRefreshing) {
              //   this.emit('regenerateToken', true);
              // } else {
              //   this.isRefreshing = true;
              //   const timeStamp = Date.now();
              //   const getTime = this.getRegenerateTokenTime();
              //   if (parseInt(getTime, 10) >= timeStamp - 1 * 10000) {
              //     this.setSession(null, null);
              //     this.emit('onLogout');
              //     this.removeRegnerateTokenTime();
              //   } else {
              //     if (!getTime) this.setRegenerateTokenTime(timeStamp);
              //     this.emit('regenerateToken', false);
              //   }
              // }
            } else if (err.response && err.response.data.status === 500) {
              this.emit('serverError', { message: err.code, code: err?.response?.data?.status });
              // Logging out a user if the token has expired.
            } else if (err.code === 'ECONNABORTED') {
              this.emit('axiosError', { message: 'Service is currently unavailable. Please try again later.' });
            } else {
              this.emit('axiosError', err);
            }
          }
        });
      }
    );
  };

  setRegenerateTokenTime = (value) => {
    sessionStorage.setItem('isRegenerateToken', value);
  };

  getRegenerateTokenTime = () => {
    return window.sessionStorage.getItem('isRegenerateToken');
  };

  removeRegnerateTokenTime = () => {
    sessionStorage.removeItem('isRegenerateToken');
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    const refresh_token = this.getRefreshToken();

    if (!access_token && !refresh_token) {
      this.emit('onNoAccessToken');
      // Get all app configurations in the app initial render.
      this.currencyFormat();
      return;
    }
    // Validating token
    if (this.isAuthTokenValid(access_token, refresh_token)) {
      this.setSession(access_token, refresh_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null, null);
      this.emit('onAutoLogout', 'Session expired. Kindly log in again');
    }
  };

  currencyFormat = () => {
    console.log("currencyvalues from jwtService")
    return new Promise((resolve, reject) => {
      if (!sessionStorage.getItem('currencyValues')) {
        sessionStorage.setItem('currencyValues', JSON.stringify(''));
        axios
          .post(
            jwtServiceConfig.masterGetAppConfigs,
            { countryCode: 'BW' },
            {
              headers: {
                agencyCode: 'BOB',
              },
            }
          )
          .then((res) => {
            console.log('AppConfig', res?.data?.data);
            if (res.data.responseCode === 0) {
              resolve(res);
              sessionStorage.setItem('currencyValues', JSON.stringify(res?.data?.data?.currencySymbol));
              sessionStorage.setItem('dateFormat', JSON.stringify(res?.data?.data?.uiDateFormat));
              sessionStorage.setItem('pswrd', JSON.stringify(res?.data?.data?.pswdLength));
            } else {
              reject(res.data);
              this.emit('currency', res.data);
            }
          });
      }
    });
  };

  signInWithEmailAndPassword = (userName, password) => {
    delete axios.defaults.headers.common.Authorization;
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.login, {
          loginUserName: userName,
          password,
        })
        .then((response) => {
          console.log(response.data.data);
          if (response?.data?.data !== null) {
            axios.defaults.headers.post.agencyCode = response.data.data.agencyCode;
          }

          if (response.data.responseCode === 0 && response?.data?.data?.token !== null) {
            let permissionData = response?.data?.data?.rolePermissions || [];
            const hasP00003 = permissionData?.some((item) => item?.permissionId === 'P00003');

            if (!response?.data?.data.agencyCode && response?.data?.data.bankId) {
              if (hasP00003) {
                permissionData = permissionData
                  .map((item) => {
                    if (item?.permissionId === 'P00003') {
                      return {
                        ...item,
                        children: item.children?.filter((child) => child === 'P00105') || [],
                      };
                    }
                    return item;
                  })
                  .filter((item) => item?.permissionId !== 'P00003' || item.children?.length > 0);
              } else {
                permissionData = [];
                this.logout();
                reject(response?.data?.errors);
              }
              console.log('permissionData', permissionData);
              if (permissionData.length === 0) {
                console.log("User doesn't have access");
                return;
              }
            }
            this.setSession(response.data.data.token, response.data.data.refreshToken);
            // this.setRoles(response.data.data);

            this.emit('onLogin', {
              ...response?.data?.data,
              rolePermissions: permissionData,
            });

            console.log('response?.data?.data:', response?.data?.data?.rolePermissions);
            resolve(response.data.data);
          } else if (response.data.responseCode === 170) {
            this.setSession(response.data.data.token, response.data.data.refreshToken);
            reject(response.data);
          } else if (response.data.responseCode === 215) {
            reject(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  signInWithOtp = (loginUserName, otp) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.login2FA, {
          loginUserName,
          otp,
        })
        .then((response) => {
          console.log(response);
          if (response?.data?.data !== null) {
            axios.defaults.headers.post.agencyCode = response.data.data.agencyCode;
          }
          // response?.data?.data !== null ? (axios.defaults.headers.post.agencyCode = response.data.data.agencyCode) : null;
          if (response.data.responseCode === 0 && response?.data?.data?.token !== null) {
            this.setSession(response.data.data.token, response.data.data.refreshToken);
            // this.setRoles(response.data.data);
            this.emit('onLogin', response?.data?.data);
            resolve(response.data.data);
          } else if (response.data.responseCode === 170) {
            this.setSession(response.data.data.token, response.data.data.refreshToken);
            reject(response.data);
          } else {
            reject(response.data);
          }
        });
    });
  };

  signInWithToken = () => {
    // The following Api no longer need token so deleting header
    delete axios.defaults.headers.common.Authorization;
    return new Promise((resolve, reject) => {
      axios
        .post(
          jwtServiceConfig.refresh,
          {
            refreshToken: this.getRefreshToken(),
            // authorizationToken: this.getAccessToken(),
          },
          {
            headers: {
              drcsOldAccessToken: this.getAccessToken(),
            },
          }
        )
        .then((response) => {
          if (response?.data?.data !== null) {
            axios.defaults.headers.post.agencyCode = response.data.data.agencyCode;
          }

          if (response.data.responseCode === 0 && response?.data?.data?.token !== null) {
            let permissionData = response?.data?.data?.rolePermissions || [];
            if (!response?.data?.data.agencyCode && response?.data?.data.bankId) {
              const hasP00003 = permissionData.some((item) => item.permissionId === 'P00003');

              if (hasP00003) {
                // If P00003 exists, filter its children to only include P00105
                console.log('hasP00003', hasP00003);
                permissionData = permissionData
                  .map((item) => {
                    if (item.permissionId === 'P00003') {
                      return {
                        ...item,
                        children: item.children?.filter((child) => child === 'P00105') || [],
                      };
                    }
                    return item;
                  })
                  .filter((item) => item.permissionId !== 'P00003' || item.children.length > 0);
              } else {
                permissionData = [];
                this.logout();
                reject(response?.data?.errors);
              }

              console.log('Filtered permissionData:', permissionData);

              if (permissionData.length === 0) {
                console.log("User doesn't have access");
                return;
              }
            }
            this.setSession(response.data.data.token, response.data.data.refreshToken);
            // this.setRoles(response.data.data);
            // this.emit('onLogin', response?.data?.data);
            this.emit('onLogin', {
              ...response?.data?.data,
              rolePermissions: permissionData,
            });

            resolve(response.data.data);
          } else {
            this.logout();
            reject(response?.data?.errors);
          }
        })
        .catch((error) => {
          console.log(error);
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token, refresh_token) => {
    if (access_token && refresh_token) {
      sessionStorage.setItem('jwt_access_token', access_token);
      sessionStorage.setItem('jwt_refresh_token', refresh_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      sessionStorage.removeItem('jwt_access_token');
      sessionStorage.removeItem('jwt_refresh_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null, null);
    this.emit('onLogout', 'Logged out');
  };

  deleteToken = (user) => {
    return new Promise((resolve) => {
      sessionStorage.removeItem('navBar');
      if (user.refreshToken) {
        axios.post(jwtServiceConfig.logout, { refreshToken: user.refreshToken }).then((res) => {
          if (res.data.responseCode === 0) {
            resolve(res);
            this.logout();
          }
        });
      } else {
        this.logout();
      }
    });
  };

  isAuthTokenValid = (access_token, refresh_token) => {
    if (!access_token && !refresh_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  regenerateToken = () => {
    delete axios.defaults.headers.common.Authorization;
    axios.defaults.headers.common.agencyCode = '';
    return new Promise((resolve, reject) => {
      const data = {
        loginUserName: this.getUserName(),
        refreshToken: this.getRefreshToken(),
      };
      axios.post(jwtServiceConfig.regenerateToken, data).then((repsonse) => {
        if (repsonse.data.responseCode === 0) {
          this.setSession(repsonse?.data?.data?.token, repsonse?.data?.data?.refreshToken);
          resolve(repsonse?.data?.data?.token);
          // this.emit('regenerateToken', false);
          console.log(repsonse?.data?.data);
          this.isRefreshing = false;
        } else if (repsonse.data.responseCode === 197) {
          this.isRefreshing = false;
          this.emit('onAutoLogout', 'Session expired. Kindly log in again');
          // this.logout();

          console.log('else case of responseCode 197', repsonse?.data);
        } else {
          reject(repsonse?.data?.errors);
          this.isRefreshing = false;
          this.logout();
        }
      });
    });
  };

  getUserName = () => {
    if (this.getAccessToken()) {
      const token = this.getAccessToken();
      const decoded = jwtDecode(token);
      console.log('decoded', decoded);
      return decoded ? decoded.preferred_username : null;
    }
    return null;
  };

  getAccessToken = () => {
    return window.sessionStorage.getItem('jwt_access_token');
  };

  getRefreshToken = () => {
    return window.sessionStorage.getItem('jwt_refresh_token');
  };

  getCurrencySymbol = () => {
    return window.sessionStorage.getItem('currencyValues');
  };
}

const instance = new JwtService();

export default instance;
