import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import MuiButton from '../mui/button/MuiButton';
import NavigatePrntAndDownload from './components/navigate-components/NavigatePrntAndDownload';
/* Centralized Action Renderer component for AG Grid to handle cell renderer action buttons. */

const BtnRenderer = ({ children, onClick }) => {
  return (
    <MuiButton type="basic" sx={{ fontSize: '10px', mr: '12px' }} onClick={onClick}>
      {children}
    </MuiButton>
  );
};

const ActionRendererComponent = (action, data, ...rest) => {
  const [handleView, handlePrint, handleDownload, handleReSubmit, handleReturn, handleReceipt, handleReturnEft, navLinkData] = rest;
  if (action) {
    switch (action) {
      case 'isEdit':
        return (
          <Box className="flex flex-row justify-start">
            <Box className="text-black cursor-pointer">
              <EditIcon style={{ fontSize: '18px' }} onClick={() => handleView(data)} />
            </Box>
          </Box>
        );
      case 'isView':
        return (
          <Box>
            <BtnRenderer onClick={() => handleView(data)}>View</BtnRenderer>
          </Box>
        );
      case 'isViewPrntDwld':
        return (
          <Box className="flex">
            <BtnRenderer onClick={() => handleView(data)}>View</BtnRenderer>
            <Box className="text-black hover:cursor-pointer ml-8">
              {navLinkData ? (
                <NavigatePrntAndDownload data={navLinkData} />
              ) : (
                <>
                  <PrintIcon sx={{ fontSize: '18px' }} onClick={() => handlePrint(data)} />
                  <DownloadIcon sx={{ fontSize: '18px', ml: '12px' }} onClick={() => handleDownload(data)} />
                </>
              )}
            </Box>
          </Box>
        );
      case 'isViewNavPrntDwld':
        return (
          <Box className="flex">
            <BtnRenderer onClick={() => handleView(data)}>View</BtnRenderer>
            <Box className="text-black hover:cursor-pointer ml-8">
              <NavigatePrntAndDownload data={data} />
            </Box>
          </Box>
        );
      case 'isViewReSubmit':
        return (
          <Box className="flex">
            <BtnRenderer onClick={() => handleView(data)}>View</BtnRenderer>
            <BtnRenderer onClick={() => handleReSubmit(data)}>ReSubmit</BtnRenderer>
          </Box>
        );
      case 'isViewReSubmitAndReturn':
        return (
          <Box className="flex">
            <BtnRenderer onClick={() => handleView(data)}>View</BtnRenderer>
            <BtnRenderer onClick={() => handleReSubmit(data)}>ReSubmit</BtnRenderer>
            <BtnRenderer onClick={() => handleReturn(data)}>Return</BtnRenderer>
          </Box>
        );
      case 'isViewReSubmitAndReturnEft':
        return (
          <Box className="flex">
            <BtnRenderer onClick={() => handleView(data)}>View</BtnRenderer>
            <BtnRenderer onClick={() => handleReSubmit(data)}>ReSubmit</BtnRenderer>
            <BtnRenderer onClick={() => handleReturn(data)}>Return</BtnRenderer>
            <BtnRenderer onClick={() => handleReturnEft(data)}>EFT Return</BtnRenderer>
          </Box>
        );
      case 'isViewFile':
        return (
          <Box>
            <IconButton onClick={() => handleView(data)} disabled={data?.jobStatus === "Processing"}>
              <DownloadIcon />
            </IconButton>
          </Box>
        );
      case 'isViewReceipt':
        return (
          <Box className="flex">
            <Box>
              <BtnRenderer onClick={() => handleView(data)}>View</BtnRenderer>
            </Box>
            <Box>
              <BtnRenderer onClick={() => handleReceipt(data)}>Upload</BtnRenderer>
            </Box>
            <Box className="text-black hover:cursor-pointer ml-8">
              <PrintIcon sx={{ fontSize: '18px' }} onClick={() => handlePrint(data)} />
              <DownloadIcon sx={{ fontSize: '18px', ml: '12px' }} onClick={() => handleDownload(data)} />
            </Box>
          </Box>
        );
      default:
        return '';
    }
  }
  return data;
};
export default ActionRendererComponent;
