import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import MuiDialog from 'app/shared-components/mui/dialog/MuiDialog';

const ConfirmationDialog = ({ openModal, closeModal, dialogTitle = 'Change Status', handleSubmitToCloseDialog }) => {
  return (
    <MuiDialog open={openModal} width="500px">
      <DialogTitle className="flex justify-between">
        <Typography className="text-xl font-semibold tracking-tight leading-8">{dialogTitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box className="my-12">
          <Typography className="mb-12 font-500 text-lg">Your Status change will not be saved if you cancel this action.</Typography>
        </Box>
      </DialogContent>
      <DialogActions className="flex justify-end">
        <MuiButton type="basic" onClick={handleSubmitToCloseDialog}>
          Ok
        </MuiButton>
      </DialogActions>
    </MuiDialog>
  );
};
export default ConfirmationDialog;
