// import { useState } from 'react';
import { Box, FormLabel, Card, Typography, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import ActionRendererComponent from 'app/shared-components/action-render/ActionRendererComponent';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { handleResponse } from 'app/shared-components/index';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import FormikController from 'app/shared-components/formik-controller/FormikController';
import GridItem from 'app/shared-components/mui/grid/GridItem';
import GridContainer from 'app/shared-components/mui/grid/GridContainer';
import { useRef } from 'react';

const RTGSFile = ({ rowData, setRowData }) => {
  const fileInputRef = useRef(null);
  const downloadCSVFile = (img, reqId) => {
    console.log(img);
    const linkSource = `data:application/csv;base64,${img}`;
    const downloadLink = document.createElement('a');
    const fileName = reqId;
    downloadLink.href = linkSource;
    downloadLink.target = '_blank';
    downloadLink.download = fileName;
    downloadLink.click();
  };
  
  const clearFileInput = (ref) => {
    if (ref.current) {
      const inputRef = ref;
      inputRef.current.value = null;
      return inputRef;
    }
    return ref;
  };
  const viewReceipt = async (value) => {
    const data = {
      fileNameWithPath: `${value.outputFilePath}${value.outputFileName}`,
    };
    console.log(data);
    const res = await axios.post(jwtServiceConfig.fpViewFileBase64, data);
    console.log(res);
    if (res.data.responseCode === 0) {
      const img = res?.data?.data?.base64String;
      const reqId = value?.outputFileName;
      downloadCSVFile(img, reqId);
    } else {
      handleResponse(res);
    }
  };
  const handleView = (data) => {
    viewReceipt(data);
  };
  const dropdown = [
    { value: "Select", label: "Select" },
    { value: "RTGS", label: "CMS" },
    { value: "ITMS", label: "ITMS" },
    { value: "ASYCUDA", label: "ASYCUDA" },

  ]
  const columnDefsCMSFile = [
    { field: 'id', headerName: 'Job ID' },
    { field: 'fileName', headerName: 'RTGS File' },
    { field: 'createdBy', headerName: 'Uploaded By' },

    {
      field: 'createdDateTime',
      headerName: 'DateTime',
      type: 'dataTimeFormat',
    },

    // {
    //   field: "outputFile",
    //   headerName: "Output File",
    //
    //   cellRenderer: ActionRendererOutput,
    // },
    { field: 'jobStatus', headerName: 'Status' },
    { field: 'outputFileName', headerName: 'Output File Name' },
    {
      field: 'report',
      headerName: 'Download',

      cellRenderer: ({ data }) => ActionRendererComponent('isViewFile', data, handleView),
    },
  ];

  const getAllUploadedFileDetails = async (value) => {
    if (value !== "Select") {
      const res = await axios.post(jwtServiceConfig.fpGetAllUploadedFileDetails, {
        jobType: value,
      });
      console.log(res.data);
      if (res.data.responseCode === 0) {
        setRowData(res.data.data);
      } else {
        handleResponse(res);
      }
    } else {
      setRowData([])
    }

  };
  const handleSubmitCMSFile = async (values, setFieldValue) => {
    const formData = new FormData();
    if (values.fileType === "RTGS") {
      formData.append(
        'jsonData',
        JSON.stringify({
          jobType: 'RTGS',
          fileDefinitions: [{ fileName: values?.CMSFile.name, definitionName: 'CMS' }],
        })
      );
      formData.append('fileList', values?.CMSFile);
    }
    else if (values.fileType === "ITMS") {
      formData.append(
        'jsonData',
        JSON.stringify({
          jobType: 'ITMS',
          fileDefinitions: [{ fileName: values?.CMSFile.name, definitionName: 'ITM' }],
        })
      );
      formData.append('fileList', values?.CMSFile);
    } else if (values.fileType === "ASYCUDA") {
      formData.append(
        'jsonData',
        JSON.stringify({
          jobType: 'ASYCUDA',
          fileDefinitions: [{ fileName: values?.CMSFile.name, definitionName: 'ASY' }],
        })
      );
      formData.append('fileList', values?.CMSFile);
    }

    const res = await axios.post(jwtServiceConfig.fpUploadMultipleFile, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(res?.data);
    if (res.data.responseCode === 0) {
      handleResponse(res);
      getAllUploadedFileDetails(values?.fileType);
      setFieldValue("fileType", values?.fileType)
      setFieldValue("CMSFile", undefined)
    } else {
      handleResponse(res);
    }
  };
  const handelFileType = (e, setFieldValue) => {
    setFieldValue(e.target.name, e.target.value)
    getAllUploadedFileDetails(e.target.value)
  }
  const handleFileName = (value) => {
    if (value) {
      if (value === "RTGS") {
        return "CMS"
      }
      else if (value === "ITMS") {
        return "ITMS"
      }
      else if (value === "ASYCUDA") {
        return "ASYCUDA"
      } else {
        return null
      }
    }
  }
  return (
    <>
      <Box>
        <Box className=" text-sm">BURS RTGS File Reconciliation</Box>
        <Formik initialValues={{ CMSFile: undefined, fileType: "Select" }} onSubmit={handleSubmitCMSFile}>
          {({ values, resetForm, setFieldValue }) => (
            <Box className="my-4 mr-8">
              <Box className="mt-8 ">
                <Form noValidate>
                  <Box className="flex  my-8 pr-16  flex-col sm:flex-row md:flex-row sm:px-8 md:px-8">
                    <Card className="flex flex-col items-start w-full h-[120px] p-24 rounded-none overflow-auto ">
                      <GridContainer spacing={2}>
                        <GridItem xs={10} md={3}>
                          <Box className="flex">
                            <FormikController control="select" name="fileType" label="File Type" required onChange={(e) => handelFileType(e, setFieldValue)}>
                              {dropdown?.map((option) => (
                                <MenuItem key={option?.value} value={option?.value}>
                                  {option?.label}
                                </MenuItem>
                              ))}
                            </FormikController>
                          </Box>
                        </GridItem>
                        {values.fileType !== "" && values.fileType !== undefined && values.fileType !== "Select" && (<GridItem xs={12} md={4}>    <Box className="flex-column my-8">
                          <Box className="my-8 mx-4">
                            <FormLabel>{handleFileName(values.fileType)} (File 1) </FormLabel>
                          </Box>
                          <div className="flex mx-4">
                            <div className="flex items-center">
                              <div>
                                <span className=" text-sm ">
                                  {values?.CMSFile!==undefined ? values?.CMSFile?.name : 'Select File'}
                                  {values?.CMSFile && (
                                    <CloseIcon
                                      onClick={() => {
                                        setFieldValue('CMSFile', undefined);
                                      }}
                                      style={{ marginLeft: '8px', fontSize: '16px' }}
                                    />
                                  )}
                                </span>
                              </div>
                              <div className="mx-8">
                                <MuiButton type="basic" component="label">
                                  <Typography className="text-13 font-medium tracking-tight">Browse</Typography>
                                  <input
                                    type="file"
                                    name="CMSFile"
                                    required
                                    hidden
                                    accept=".csv"
                                    ref={fileInputRef}
                                    onClick={() => clearFileInput(fileInputRef)}
                                    onChange={(event) => {
                                      setFieldValue('CMSFile', event.currentTarget.files[0]);
                                    }}
                                  />
                                </MuiButton>
                              </div>
                            </div>

                          </div>
                        </Box>
                        </GridItem>)}

                      </GridContainer>




                    </Card>

                    <Box>
                      <Box className="flex justify-end my-20 px-8">
                        {/* <Box>
                          <Button variant="outlined" size="small">
                            Cancel
                          </Button>
                        </Box> */}
                        <Box>
                          <MuiButton type="basic" sx={{ marginLeft: '10px' }} disabled={values?.CMSFile === undefined} onClick={() => handleSubmitCMSFile(values, setFieldValue)}>
                            Reconcile
                          </MuiButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
      <Box className="mt-32">
        <AgGrid rowData={rowData} columnDefs={columnDefsCMSFile} />
      </Box>


    </>
  );
};
export default RTGSFile;
