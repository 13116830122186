import JwtService from 'src/app/auth/services/jwtService';

export default function AmountCellRenderer(props) {
  if (!props?.value) return null;
  const currency = JSON.parse(JwtService.getCurrencySymbol());
  return (
    <div className="flex justify-between items-center">
      <div>{currency}</div>
      <div>
        {parseFloat(props?.value).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
    </div>
  );
}
