import { useState, useEffect } from 'react';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import { Paper, Box, Typography, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import { Formik } from 'formik';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { usePermHook } from 'src/app/custom-hooks/user-permission-hook/userPermHook';
import { permissions } from 'src/app/custom-hooks/user-permission-hook/permissions';
import MuiFormDialog from 'app/shared-components/mui/dialog/MuiFormDialog';
import FormikController from 'app/shared-components/formik-controller/FormikController';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import GridContainer from 'app/shared-components/mui/grid/GridContainer';
import GridItem from 'app/shared-components/mui/grid/GridItem';
import { handleResponse } from 'app/shared-components/index';
import ActionRendererComponent from 'app/shared-components/action-render/ActionRendererComponent';
import { validationSchemaServicePage, initialValuesServicePage, statusChangeMonitor } from '../helperFuntionQueueManagement';
import ConfirmationDialog from '../confirmation-dialog-queue-management/ConfirmationDialog';
import MuiDialog from 'app/shared-components/mui/dialog/MuiDialog';

/* Service Page
 * Bank admin can create multiple services.
 * The default service status is Inactive.
 * Service status can be changed by the edit option.
 */

const ServicePage = () => {
  const [servicePageControl, setServicePageControl] = useState({
    isOpenDialog: false,
    isEdit: false,
    selectedValues: [],
  });
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [cnfrmDlgOpen, setCnfrmDlgOpen] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const { permission } = usePermHook(permissions?.queueManagement?.servicePage);
  const dialogTitle = servicePageControl?.isEdit ? 'Edit Service' : 'Add Service';
  const [statusControl, setStatusControl] = useState({
    isOpenStsDlg: false,
    val: undefined,
  });
  const handleView = (data) => {
    setStatusControl((prev) => ({ ...prev, isOpenStsDlg: false }))
    setServicePageControl((prev) => ({ ...prev, isOpenDialog: true, isEdit: true, selectedValues: data }));

  }

  let columnDefs = [
    {
      field: 'serviceName',
      headerName: ' Name',
    },
    {
      field: 'serviceCode',
      headerName: ' Code',
    },
    {
      field: 'serviceStatus',
      headerName: 'Status',
      valueGetter: ({ data: { serviceStatus } }) => (serviceStatus === 'A' ? 'Active' : 'Suspended'),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
    },
    {
      field: 'createdDatetime',
      headerName: 'Created DateTime',
      type: 'dataTimeFormat',
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Modified By',
    },
    {
      field: 'lastUpdatedDatetime',
      headerName: 'Modified DateTime',
      type: 'dataTimeFormat',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellRenderer: ({ data }) => ActionRendererComponent('isEdit', data, handleView),
    },
  ];

  columnDefs = permission && permission?.P00217 === true ? columnDefs : columnDefs.slice(0, -1);

  const getServicesList = async () => {
    const res = await axios.post(jwtServiceConfig.serviceView, {});
    console.log(res);
    if (res.data.responseCode === 0) {
      setRowData(res.data.data);
    } else {
      handleResponse(res);
    }
  };

  useEffect(() => {
    getServicesList();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values);
    if (servicePageControl?.isEdit === false) {
      const data = {
        serviceCode: values.serviceCode,
        serviceStatus: values.serviceStatus,
        serviceName: values.serviceName,
      };

      const res = await axios.post(jwtServiceConfig.serviceAdd, data);
      if (res.data.responseCode === 0) {
        handleResponse(res);
        setServicePageControl((prev) => ({ ...prev, isOpenDialog: false, isEdit: false }));
        resetForm();
        getServicesList();
      } else {
        handleResponse(res);
      }
    } else {
      const data = {
        id: values.id,
        serviceCode: values.serviceCode,
        serviceStatus: values.serviceStatus,
        serviceName: values.serviceName,
      };

      const res = await axios.post(jwtServiceConfig.serviceEdit, data);

      if (res.data.responseCode === 0) {
        handleResponse(res);
        setServicePageControl((prev) => ({ ...prev, isOpenDialog: false, isEdit: false }));
        resetForm();
        getServicesList();
      } else {
        handleResponse(res);
      }
    }
  };

  const handleCloseServiceDialog = (resetForm, values) => {
    if (statusChangeMonitor(values, servicePageControl?.selectedValues, 'serviceStatus') && !confirmCancel && servicePageControl?.isEdit) {
      setCnfrmDlgOpen(true);
    } else {
      resetForm();
      setServicePageControl((prev) => ({ ...prev, isOpenDialog: false, isEdit: false }));
      setConfirmCancel(false);
    }
  };
  const handleChangeStatus = async () => {
    setStatusControl((prev) => ({ ...prev, isOpenStsDlg: false }));
  };
  const handleCloseStsDlg = (value, setFieldValue) => {
    const val = value === 'A' ? 'I' : 'A';
    setFieldValue('serviceStatus', val);
    setStatusControl((prev) => ({ ...prev, isOpenStsDlg: false, val }));
  };
  const handleSetStatus = (evnt, setFieldValue) => {
    const val = evnt.target.checked ? 'A' : 'I';
    setFieldValue('serviceStatus', val);
    setStatusControl((prev) => ({ ...prev, isOpenStsDlg: true, val }));
  };
  return (
    <Paper className="relative flex flex-col flex-auto p-24 prounded-2xl shadow overflow-hidden">
      <Box className="mt-4 ml-8 flex flex-row justify-between ">
        <Box>
          <Typography className="text-3xl font-semibold tracking-tight leading-8">Service Configuration</Typography>
          <Box>
            <Box>
              <h3>
                <span className="text-md">Queue Management &gt; Service Configuration</span>
              </h3>
            </Box>
          </Box>
        </Box>
        {permission?.P00216 && (
          <Box className="mt-24">
            <MuiButton
              type="basic"
              onClick={() => {
                setServicePageControl((prev) => ({ ...prev, isOpenDialog: true, isEdit: false }));
              }}
            >
              Add
            </MuiButton>
          </Box>
        )}
      </Box>
      <Box className="mt-16">
        <AgGrid rowData={rowData} columnDefs={columnDefs} overlayNoRowsTemplate="No services configured, please configure services" />
      </Box>
      <Formik
        initialValues={servicePageControl?.isEdit ? servicePageControl?.selectedValues : initialValuesServicePage}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchemaServicePage}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, resetForm }) => (
          <>
            <MuiFormDialog open={servicePageControl?.isOpenDialog} dialogTitle={dialogTitle} onClose={() => handleCloseServiceDialog(resetForm, values)}>
              <GridContainer spacing={2}>
                <GridItem xs={12}>
                  <FormikController control="textField" name="serviceCode" label="Service Code" inputProps={{ maxLength: 2 }} disabled={servicePageControl?.isEdit} required />
                </GridItem>
                <GridItem xs={12}>
                  <FormikController control="textField" name="serviceName" label="Service Name" required inputProps={{ maxLength: 25 }} />
                </GridItem>
                {permission?.P00218 && (
                  <GridItem xs={12}>
                    <FormikController
                      control="switch"
                      name="serviceStatus"
                      label="Status"
                      checked={values?.serviceStatus === 'A'}
                      onChange={(e) => {
                        // setFieldValue('serviceStatus', e.target.checked ? 'A' : 'I');
                        handleSetStatus(e, setFieldValue)
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </MuiFormDialog>
            <MuiDialog open={statusControl?.isOpenStsDlg && servicePageControl?.isEdit} width="500px">
              <DialogTitle className="flex justify-between">
                <Typography className="text-xl font-semibold tracking-tight leading-8">Change Service status</Typography>
              </DialogTitle>
              <DialogContent>
                <Box className="my-12">
                  <Typography className="mb-12 font-500 text-lg">Are you sure want to {values?.serviceStatus === 'A' ? 'active' : 'suspend'} the Service ?</Typography>
                </Box>
              </DialogContent>
              <DialogActions className="flex justify-end">
                <MuiButton type="cancel-btn" onClick={() => handleCloseStsDlg(values?.serviceStatus, setFieldValue)}>
                  No
                </MuiButton>
                <MuiButton type="basic" onClick={handleChangeStatus}>
                  Yes
                </MuiButton>
              </DialogActions>
            </MuiDialog></>
        )}
      </Formik>
      <ConfirmationDialog
        openModal={cnfrmDlgOpen}
        closeModal={() => {
          setCnfrmDlgOpen(false);
        }}
        handleSubmitToCloseDialog={() => {
          setConfirmCancel(true);
          setCnfrmDlgOpen(false);
        }}
      />
    </Paper>
  );
};
export default ServicePage;
