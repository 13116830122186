import { useState, useEffect } from 'react';
import { Box, DialogContent, DialogTitle, Paper, Typography, Tab, FormLabel, Card } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import { Form, Formik } from 'formik';
import CustomDialogBox from 'app/shared-components/custom-dialog-box/CustomDialog';
import ActionRendererComponent from 'app/shared-components/action-render/ActionRendererComponent';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { handleResponse } from 'app/shared-components/index';
import RTGSFile from './components/RTGSFile';
import OAGComBankTransfer from './components/OAGComBankTransfer';
import BURComBankTransfer from './components/BURComBankTransfer';
import ATMEJournalExtractFile from './components/ATMEJournalSwitchExtractCBSFile';
import EmpayPOS from './components/EmpayPOS';

const FileReconcilationPage = () => {
  const [open, setOpen] = useState(false);
  const [viewOpen] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [rowData, setRowData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState(undefined);
  const [file1, setFile1] = useState(undefined);
  const [file2, setFile2] = useState(undefined);

  const initialValueFile2 = {
    T24BISSFile: null,
    R3ReportFile: null,
  };

  const viewReceipt = async (value) => {
    const data = {
      fileNameWithPath: `${value.filePath}${value.outputFileName}`,
    };
    console.log(data);
    const res = await axios.post(jwtServiceConfig.fpViewFileBase64, data);
    console.log(res);
    if (res.data.responseCode === 0) {
      setImage(`data:application/csv;base64,${res?.data?.data?.base64String}`);
    } else {
      handleResponse(res);
    }
  };
  const handleView = (data) => {
    setImage(undefined);
    viewReceipt(data);
  };

  const columnDefs = [
    {
      field: 'createdDatetime',
      headerName: 'Date',
    },
    { field: 'fileName', headerName: 'Output File Name' },
    { field: 'createdBy', headerName: 'Created By' },
    { field: 'atmeJournalfileName', headerName: 'ATM e-Journal File' },
    { field: 'switchextractfileName', headerName: 'Switch Extract File' },
    { field: 'status', headerName: 'Status' },
    {
      field: 'report',
      headerName: 'Report',
      cellRenderer: ({ data }) => ActionRendererComponent('isViewFile', data, handleView),
    },
  ];
  const handleSubmitReceipt = () => {
    setOpen(true);
    setLoader(true);
  };
  if (loader) {

  }
  const getAllUploadedFileDetails = async (value) => {
    if (value !== "") {
      const res = await axios.post(jwtServiceConfig.fpGetAllUploadedFileDetails, {
        jobType: value,
      });
      console.log(res.data);
      if (res.data.responseCode === 0) {
        setRowData(res.data.data);
      } else {
        handleResponse(res);
      }
    }
    else {
      setRowData([])
    }
  };

  useEffect(() => {
    getAllUploadedFileDetails('EJ_SW');
  }, []);

  return (
    <>
      <Paper className="relative flex flex-col flex-auto p-24 prounded-2xl shadow overflow-hidden">
        <Box className="mt-4 ml-8">
          <Box className="flex justify-between">
            <Box>
              <Typography className="text-3xl font-semibold tracking-tight leading-8">File Reconciliation</Typography>
              <h3>
                <span className="text-md">File Reconciliation</span>
              </h3>
            </Box>
          </Box>
        </Box>
        <Card>
          <TabContext value={tabValue}>
            <Box className="mt-24 flex justify-between">
              <TabList
                onChange={(_, newTabValue) => {
                  setTabValue(newTabValue);
                }}
              >
                <Tab label="ATM e-Journal,Switch Extract & CBS File" value="1" className="text-sm font-500 min-h-40 min-w-64 mx-4 px-12" onClick={() => getAllUploadedFileDetails('EJ_SW')} />
                <Tab label="RTGS" value="2" className="text-sm font-500 min-h-40 min-w-64 mx-4 px-12" onClick={() => getAllUploadedFileDetails('')} />
                {/* <Tab
                label="EFT"
                value="3"
                className="text-sm font-500 min-h-40 min-w-64 mx-4 px-12 "
              /> */}
                <Tab label="OAG Commercial Bank Transfers" value="4" className="text-sm font-500 min-h-40 min-w-64 mx-4 px-12" onClick={() => getAllUploadedFileDetails('OAG_COM')} />
                <Tab label="BURS Commercial Bank Transfers" value="5" className="text-sm font-500 min-h-40 min-w-64 mx-4 px-12" onClick={() => getAllUploadedFileDetails('BUR_COM')} />
                <Tab label="emPAY POS" value="6" className="text-sm font-500 min-h-40 min-w-64 mx-4 px-12 " onClick={() => getAllUploadedFileDetails('POS')} />
                {/* emPAY IPG is not developed right now, so we are not displaying this */}
                {/* <Tab label="emPAY IPG" value="7" disabled className="text-sm font-500 min-h-40 min-w-64 mx-4 px-12 " /> */}
              </TabList>
            </Box>
            <TabPanel value="1" index={0}>
              <ATMEJournalExtractFile getAllUploadedFileDetails={() => getAllUploadedFileDetails('EJ_SW')} rowData={rowData} setRowData={setRowData} />
            </TabPanel>
            <TabPanel value="2" index={1}>
              <RTGSFile rowData={rowData} setRowData={setRowData} />
            </TabPanel>
            <TabPanel value="4" index={3}>
              <OAGComBankTransfer rowData={rowData} setRowData={setRowData} />
            </TabPanel>
            <TabPanel value="5" index={4}>
              <BURComBankTransfer rowData={rowData} setRowData={setRowData} />
            </TabPanel>
            <TabPanel value="6" index={5}>
              <EmpayPOS rowData={rowData} setRowData={setRowData} />
            </TabPanel>
            {/* <TabPanel value="7" index={6}>
              <Box className=" text-sm">emPay IPG File Reconciliation </Box>
              <Box className="mt-20 text-sm font-medium">Select and Upload the files to reconcile</Box>
              <Box>
                <Formik initialValues={initialValueFile2} onSubmit={handleSubmitReceipt} validateOnBlur={false}>
                  {({ values, setFieldValue }) => (
                    <Box className="my-4 mr-8">
                      <Box className="mt-8 ">
                        <Form noValidate>
                          <Box className="flex justify-between my-8 pr-16">
                            <Card className="flex flex-col items-start w-full h-[120px] p-24 rounded-none overflow-auto ">
                              <Box className="flex-column my-8">
                                <Box className="my-8">
                                  <FormLabel>T24/BISS: (File 1) </FormLabel>
                                </Box>
                                <div className="flex items-center">
                                  <div>
                                    <span className=" text-sm ">
                                      {values?.T24BISSFile ? values?.T24BISSFile?.name : 'Select File'}
                                      {values?.T24BISSFile && (
                                        <CloseIcon
                                          onClick={() => {
                                            setFieldValue('T24BISSFile', null);
                                            setFile1(undefined);
                                          }}
                                          style={{ marginLeft: '8px', fontSize: '16px' }}
                                        />
                                      )}
                                    </span>
                                  </div>
                                  <div className="mx-12">
                                    <MuiButton type="basic" component="label">
                                      <Typography className="text-13 font-medium tracking-tight">Browse</Typography>

                                      <input
                                        type="file"
                                        name="T24BISSFile"
                                        required
                                        multiple
                                        hidden
                                        onChange={(event) => {
                                          console.log(event);
                                          setFile1(event.currentTarget.files[0]);
                                          setFieldValue('T24BISSFile', event.currentTarget.files[0]);
                                        }}
                                      />
                                    </MuiButton>
                                  </div>
                                </div>
                              </Box>
                              <div className="mt-20 flex justify-end">
                                <MuiButton type="basic" component="label" disabled={file1 === undefined}>
                                  Upload
                                </MuiButton>
                              </div>
                            </Card>
                            <Card className="flex flex-col items-start w-full h-[120px] p-24 rounded-none overflow-auto ">
                              <Box className="flex-column my-8">
                                <Box className="my-8">
                                  <FormLabel>R3 Report : (File 2)</FormLabel>
                                </Box>
                                <div className="flex items-center">
                                  <div>
                                    <span className="text-sm">
                                      {values?.R3ReportFile ? values?.R3ReportFile?.name : 'Select File'}
                                      {values?.R3ReportFile && (
                                        <CloseIcon
                                          onClick={() => {
                                            setFieldValue('R3ReportFile', null);
                                            setFile2(undefined);
                                          }}
                                          style={{ marginLeft: '8px', fontSize: '16px' }}
                                        />
                                      )}
                                    </span>
                                  </div>
                                  <div className="mx-12">
                                    <MuiButton type="basic" component="label">
                                      <Typography className="text-13 font-medium tracking-tight">Browse</Typography>
                                      <input
                                        type="file"
                                        name="R3ReportFile"
                                        required
                                        multiple
                                        hidden
                                        onChange={(event) => {
                                          console.log(event);
                                          setFile2(event.currentTarget.files[0]);
                                          setFieldValue('R3ReportFile', event.currentTarget.files[0]);
                                        }}
                                      />
                                    </MuiButton>
                                  </div>
                                </div>
                              </Box>
                              <div className="mt-20 flex justify-end">
                                <MuiButton type="basic" component="label" disabled={file2 === undefined}>
                                  Upload
                                </MuiButton>
                              </div>
                            </Card>

                            <Box>
                              <Box className="flex justify-end my-20 px-8">
                                <Box>
                                  <MuiButton type="cancel-btn">Cancel</MuiButton>
                                </Box>
                                <Box>
                                  <MuiButton type="submit" sx={{ marginLeft: '10px' }} disabled={values?.myFile === null}>
                                    Reconcile
                                  </MuiButton>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Form>
                      </Box>
                    </Box>
                  )}
                </Formik>
              </Box>
              <Box className="mt-32">
                <AgGrid rowData={rowData} columnDefs={columnDefs} />
              </Box>
              <CustomDialogBox
                open={open}
                onClose={() => setOpen(false)}
                width="800px"
                children={
                  <>
                    <DialogTitle>File Reconciliation</DialogTitle>
                    <DialogContent>
                      <Box className="flex flex-row">
                        <Box className="w-1/3">
                          <Card className="flex flex-col items-start w-full h-[240px] p-24 rounded-lg shadow rounded-lg hover:shadow-xl transition-shadow duration-150 ease-in-out scroll-y-auto">
                            <div className="flex flex-col w-full">
                              <Typography className="text-lg font-bold">Processing Files</Typography>
                            </div>
                            <div className="my-40 mx-80">{loader && <CircularProgress color="inherit" />}</div>
                          </Card>
                        </Box>
                        <Box className="w-2/3">
                          <Card className="flex flex-col items-start w-full h-[240px] p-24 rounded-lg shadow rounded-lg hover:shadow-xl transition-shadow duration-150 ease-in-out scroll-y-auto">
                            <div className="flex flex-col w-full">
                              <Typography className="text-lg font-bold">Process Log</Typography>
                            </div>
                            <div className="mt-16 space-y-8">
                              <div className="flex scroll-auto">
                                <FuseSvgIcon className="text-green-600" size={20}>
                                  heroicons-solid:check
                                </FuseSvgIcon>
                                <Typography className="ml-2 leading-5">Reconciliation complete</Typography>
                              </div>
                            </div>

                            <div className="flex flex-col mt-8">
                              <div className="mt-16 space-y-8">
                                <div className="flex scroll-auto">
                                  <Typography className="ml-2 leading-5">1500 record reconciled</Typography>
                                </div>
                                <div className="flex scroll-auto">
                                  <Typography className="ml-2 leading-5">950 record matched</Typography>
                                </div>
                                <div className="flex scroll-auto">
                                  <Typography className="ml-2 leading-5">550 record mismatched</Typography>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between">
                              <div className="mt-24">
                                <MuiButton type="basic" sx={{ fontSize: '10px' }} onClick={() => setOpen(false)}>
                                  Cancel
                                </MuiButton>
                              </div>
                              <div className="mt-24 mx-48">
                                <MuiButton type="basic" sx={{ fontSize: '10px' }}>
                                  Excel
                                </MuiButton>
                                <MuiButton type="basic" sx={{ fontSize: '10px', marginLeft: '10px' }}>
                                  PDF
                                </MuiButton>
                              </div>
                            </div>
                          </Card>
                        </Box>
                      </Box>
                    </DialogContent>
                  </>
                }
              />
            </TabPanel> */}

          </TabContext>
        </Card>
      </Paper>
      <Box>{image && <iframe src={image} width="500px" height="500px" title="fileReconsilationIframe" />}</Box>
    </>
  );
};

export default FileReconcilationPage;
