import * as Yup from 'yup';

export const getPwdConfig = () => window.sessionStorage.getItem('pswrd');

export const validationSchemaVeryStrong = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{10,99}$/,
      'Minimum length 10. At least 1 uppercase, 1 lowercase, 1 number and 1 special character required'
    )
    .min(10, 'Minimum length required is 10'),
  confirmPassword: Yup.string()
    .required('required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const validationSchemaStrong = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,99}$/,
      'Minimum length 8. At least 1 uppercase, 1 lowercase, 1 number and 1 special character required'
    )
    .min(8, 'Minimum length required is 8')
    .trim(),
  confirmPassword: Yup.string()
    .required('required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const validationSchemaMedium = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{6,99}$/,
      'Minimum length 6. At least 1 uppercase, 1 lowercase and 1 number required'
    )
    .min(6, 'Minimum length required is 6')
    .trim(),
  confirmPassword: Yup.string()
    .required('required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const getValidationSchema = (val) => {
  switch (val) {
    case 6:
      return validationSchemaMedium;
    case 8:
      return validationSchemaStrong;
    case 10:
      return validationSchemaVeryStrong;
    default:
      return validationSchemaMedium;
  }
};

// validation schema change password

const validationSchemaChgePwVeryStrong = Yup.object().shape({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .required('Required')
    .min(10, 'Minimum length required is 10')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{10,99}$/,
      'Minimum length 10. At least 1 uppercase, 1 lowercase, 1 number and 1 special character required'
    ),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});
const validationSchemaChgePwStrong = Yup.object().shape({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,99}$/,
      'Minimum length 8. At least 1 uppercase, 1 lowercase, 1 number and 1 special character required'
    )
    .min(8, 'Minimum length required is 8')
    .trim(),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});
const validationSchemaChgePwMedium = Yup.object().shape({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{6,99}$/,
      'Minimum length 6. At least 1 uppercase, 1 lowercase and 1 number required'
    )
    .min(6, 'Minimum length required is 6')
    .trim(),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

export const getValidationSchemaChangePassword = (val) => {
  switch (val) {
    case 10:
      return validationSchemaChgePwVeryStrong;
    case 8:
      return validationSchemaChgePwStrong;
    case 6:
      return validationSchemaChgePwMedium;
    default:
      return validationSchemaChgePwMedium;
  }
};
