/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from 'formik';
import DropDown from './components/DropDown';
import Input from './components/Input';
import MuiSwitch from './components/MuiSwitch';
import MuiDatePicker from './components/MuiDatePicker';
import MuiAutoComplete from './components/AutoComplete';
import ContactNumber from './components/ContactNumber';
import Currency from './components/Currency';
import Password from './components/Password';
import NumericField from './components/NumericField';

/** Formik Controller Component
 * This component is used to centralize the inputs across the entire application.
 */

const FormikController = (props) => {
  const { control, ...rest } = props;
  const { errors, touched } = useFormikContext();

  switch (control) {
    case 'textField':
      return <Input {...rest} errors={errors} touched={touched} />;
    case 'select':
      return <DropDown {...rest} errors={errors} touched={touched} />;
    case 'switch':
      return <MuiSwitch {...rest} errors={errors} touched={touched} />;
    case 'datePicker':
      return <MuiDatePicker {...rest} errors={errors} touched={touched} />;
    case 'autoComplete':
      return <MuiAutoComplete {...rest} errors={errors} touched={touched} />;
    case 'contactNumber':
      return <ContactNumber {...rest} errors={errors} touched={touched} />;
    case 'currency':
      return <Currency {...rest} errors={errors} touched={touched} />;
    case 'password':
      return <Password {...rest} errors={errors} touched={touched} />;
    case 'numericField':
      return <NumericField {...rest} errors={errors} touched={touched} />;
    default:
      return null;
  }
};

export default FormikController;
