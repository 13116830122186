import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  message: '',
};

const snackBarSlice = createSlice({
  name: 'snackBar',
  initialState,
  reducers: {
    openSnackBar: (state, payload) => {
      state.open = true;
      state.message = payload.payload;
    },
    closeSnackBar: (state) => {
      state.open = false;
      state.message = null;
    },
  },
});

export const selectSnackBarState = ({ snackBar }) => snackBar;

export const { openSnackBar, closeSnackBar } = snackBarSlice.actions;

export default snackBarSlice.reducer;
