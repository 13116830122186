
import { useRef } from 'react';
import { Box, FormLabel, Card, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import ActionRendererComponent from 'app/shared-components/action-render/ActionRendererComponent';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { handleResponse } from 'app/shared-components/index';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
 
const EmpayPOS = ({ rowData, setRowData }) => {
  const gridRef = useRef();
  console.log(gridRef);
  const fileInputRef = useRef(null);

  const clearFileInput = (ref) => {
    if (ref.current) {
      const inputRef = ref;
      inputRef.current.value = null;
      return inputRef;
    }
    return ref;
  };
  const downloadCSVFile = (img, reqId) => {
    console.log(img);
    console.log(reqId);
    const linkSource = `data:application/csv;base64,${img}`;
    const downloadLink = document.createElement('a');
    const fileName = `${reqId}`;
    downloadLink.href = linkSource;
    downloadLink.target = '_blank';
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const viewReceipt = async (value) => {
    const data = {
      fileNameWithPath: `${value.outputFilePath}${value.outputFileName}`,
    };
    console.log(data);
    const res = await axios.post(jwtServiceConfig.fpViewFileBase64, data);
    console.log(res);
    if (res.data.responseCode === 0) {
      const img = res?.data?.data?.base64String;
      const reqId = value?.outputFileName;
      downloadCSVFile(img, reqId);
    } else {
      handleResponse(res);
    }
  };
  const handleView = (data) => {
    viewReceipt(data);
  };
 
  const columnDefsPOSFile = [
    { field: 'id', headerName: 'Job ID' },
    { field: 'fileName', headerName: 'POS File' },
    { field: 'createdBy', headerName: 'Uploaded By' },
    {
      field: 'createdDateTime',
      headerName: 'DateTime',
      type: 'dataTimeFormat',
    },
    { field: 'jobStatus', headerName: 'Status' },
    { field: 'outputFileName', headerName: 'Output File Name' },
    {
      field: 'report',
      headerName: 'Download',
      cellRenderer: ({ data }) => ActionRendererComponent('isViewFile', data, handleView),
    },
  ];
  const getAllUploadedFileDetails = async () => {
    const res = await axios.post(jwtServiceConfig.fpGetAllUploadedFileDetails, {
      jobType: 'POS',
    });
    console.log(res.data);
    if (res.data.responseCode === 0) {
      setRowData(res.data.data);
    } else {
      handleResponse(res);
    }
  };
  const handleSubmitPOSFile = async (values, resetForm) => {
    const formData = new FormData();
    formData.append(
      'jsonData',
      JSON.stringify({
        jobType: 'POS',
        fileDefinitions: [{ fileName: values?.POSFile.name, definitionName: 'POS' }],
      })
    );
    formData.append('fileList', values?.POSFile);
    const res = await axios.post(jwtServiceConfig.fpUploadMultipleFile, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(res?.data);
    if (res.data.responseCode === 0) {
      handleResponse(res);
      getAllUploadedFileDetails();
      resetForm();
    } else {
      handleResponse(res);
    }
  };
 
  return (
    <>
      <Box className=" text-sm">emPAY POS File Reconciliation</Box>
      <Box>
        <Formik initialValues={{ POSFile: undefined }} onSubmit={handleSubmitPOSFile} validateOnBlur={false}>
          {({ values, resetForm, setFieldValue }) => (
            <Box className="my-4 mr-8">
              <Box className="mt-8 ">
                <Form noValidate>
                  <Box className="flex justify-between my-8 pr-16 flex-col sm:flex-row md:flex-row sm:px-8 md:px-8">
                    <Card className="flex flex-col items-start w-full h-[120px] p-24 rounded-none overflow-auto ">
                      <Box className="flex-column my-8">
                        <Box className="my-8">
                          <FormLabel>emPAY POS: (File 1) </FormLabel>
                        </Box>
                        <div className="flex">
                          <div className="flex items-center">
                            <div>
                              <span className=" text-sm ">
                                {values?.POSFile ? values?.POSFile?.name : 'Select File'}
                                {values?.POSFile && (
                                  <CloseIcon
                                    onClick={() => {
                                      setFieldValue('POSFile', null);
                                    }}
                                    style={{ marginLeft: '8px', fontSize: '16px' }}
                                  />
                                )}
                              </span>
                            </div>
                            <div className="mx-8">
                              <MuiButton type="basic" component="label">
                                <Typography className="text-13 font-medium tracking-tight">Browse</Typography>
                                <input
                                  type="file"
                                  name="POSFile"
                                  required
                                  hidden
                                  accept=".xlsx, .xls"
                                  ref={fileInputRef}
                                  onClick={() => clearFileInput(fileInputRef)}
                                  onChange={(event) => {
                                    setFieldValue('POSFile', event.currentTarget.files[0]);
                                  }}
                                />
                              </MuiButton>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Card>
                    <Box>
                      <Box className="flex justify-end my-20 px-8">
                        <Box>
                          <MuiButton type="basic" className="ml-8" disabled={values?.POSFile === undefined} onClick={() => handleSubmitPOSFile(values, resetForm)}>
                            Reconcile
                          </MuiButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
      <Box className="mt-32">
        <AgGrid rowData={rowData} columnDefs={columnDefsPOSFile} />
      </Box>
    </>
  );
};
export default EmpayPOS;
 