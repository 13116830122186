import { Dialog } from '@mui/material';

const MuiDialog = (props) => {
  const { open, width, children } = props;
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: width,
            minWidth: width,
            
            '@media (max-width:600px)': {
              width: '75%',
              minWidth: '75%',
            },
            '@media (max-width:960px)': {
              width: '75%',
              minWidth: '75%',
            },
          },
        },
      }}
      width={width}
    >
      {children}
    </Dialog>
  );
};

export default MuiDialog;
