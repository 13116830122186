import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { handleResponse } from 'app/shared-components/index';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { selectUser } from 'app/store/userSlice';
import { motion } from 'framer-motion';
import TerminalCard from './components/TerminalCard';

/* Teller & Terminal Status
 * This page will show all the teller and terminal list based on the logged-in user location code.
 * The logged-in user can change the terminal/teller service and operating status.
 */

const TerminalStatusPage = () => {
  const [terminalDetails, setTerminalDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector(selectUser);

  const getTerminalDetails = async () => {
    await axios
      .post(jwtServiceConfig.terminalGetTerminalStatusForLocation, {
        locationCode: user.locationCode,
      })
      .then((res) => {
        if (res.data.responseCode === 0) {
          setIsLoading(false);
          setTerminalDetails(res?.data?.data?.filter((item) => item?.terminalStatus === 'A'));
          console.log(res.data.data);
        } else {
          handleResponse(res);
        }
      });
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
    },
  };

  useEffect(() => {
    getTerminalDetails();
  }, []);

  return (
    <div>
      <div className="my-8">
        <div className="flex justify-between">
          <div>
            <Typography className="text-3xl font-semibold tracking-tight leading-8">Teller & Terminal Status</Typography>
            <h3>
              <span className="text-md">Queue Management &gt; Teller & Terminal Status</span>
            </h3>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full mx-auto px-24">
        {terminalDetails?.length > 0 ? (
          <motion.div className="grid sm:grid-cols-2 md:grid-cols-3 gap-32 mt-32 sm:mt-40" variants={container} initial="hidden" animate="show">
            {terminalDetails?.map((terminal) => (
              <motion.div variants={item} key={terminal?.terminalId}>
                <TerminalCard terminal={terminal} getTerminalDetails={getTerminalDetails} locationCode={user?.locationCode} />
              </motion.div>
            ))}
          </motion.div>
        ) : (
          <div className="flex flex-1 items-center justify-center">
            <Typography color="text.secondary" className="text-24 my-24">
              {isLoading === true ? 'Fetching Terminals!' : 'No Terminals Found!'}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default TerminalStatusPage;
