import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import { selectUser } from 'app/store/userSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import settingsConfig from 'app/configs/settingsConfig';
import axios from 'axios';
import { LicenseManager } from 'ag-grid-enterprise';
import withAppProviders from './withAppProviders';
import { AuthProvider } from './auth/AuthContext';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import ErrorBoundary from './shared-components/error-boundary/ErrorBoundary';

// Setting up Ag-grid licensekey
const LicenseKey =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-055928}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Novus_Technologies_Pte_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{DRCS}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{DRCS}_need_to_be_licensed___{DRCS}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_March_2025}____[v3]_[01]_MTc0MTA0NjQwMDAwMA==1f2d39af5382daedfee8b494c492278c';
LicenseManager.setLicenseKey(LicenseKey);

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

function App() {
  const user = useSelector(selectUser);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);

  /**
   * Axios HTTP Request defaults
   */
  axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
  //
  axios.defaults.headers.post.deviceId = '1';
  axios.defaults.headers.post.channelId = '01';
  axios.defaults.headers.post.instnId = '1';

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <FuseTheme theme={mainTheme} direction={langDirection}>
        <AuthProvider>
          <BrowserRouter>
            <ErrorBoundary>
              <FuseAuthorization userRole={user.roleName} loginRedirectUrl={settingsConfig.loginRedirectUrl}>
                <SnackbarProvider
                  maxSnack={5}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  classes={{
                    containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                  }}
                >
                  <FuseLayout layouts={themeLayouts} />
                </SnackbarProvider>
              </FuseAuthorization>
            </ErrorBoundary>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </CacheProvider>
  );
}

export default withAppProviders(App)();
